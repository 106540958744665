import { COLORS, flexCentered, STYLE_CONSTS } from "~/src/styles/config.style";

export const useStyles = () => ({
  dialogPaper: {
    minHeight: "389px",
    maxHeight: "389px",
    overflowY: "hidden"
  }
});

export const useSlideStyles = () => ({
  dialog: {
    position: "absolute",
    right: -30,
    minHeight: "804px",
    maxHeight: "804px",
    width: "712px"
  }
});

const styles = {
  button: {
    backgroundColor: COLORS.MAIN_ORANGE,
    color: COLORS.WHITE,
    width: "146px",
    height: "36px",
    borderRadius: "20px",
    marginBottom: "20px"
  },
  disabledButton: {
    backgroundColor: "#EBEBEB",
    color: COLORS.BLACK,
    width: "146px",
    height: "36px",
    borderRadius: "20px",
    opacity: "30%",
    marginBottom: "20px"
  },
  centered: {
    ...flexCentered,
    padding: "44px"
  },
  declineBtn: {
    backgroundColor: "#EBEBEB",
    color: COLORS.BLACK,
    width: "146px",
    height: "36px",
    borderRadius: "20px",
    margin: "0 0 14px 0"
  },
  dialogActions: {
    paddingVertical: "41px",
    position: "absolute",
    bottom: "70px",
    display: "flex",
    flexDirection: "column"
  },
  dialogContent: {
    height: "77px"
  },
  dialogText: {
    padding: "30px 44px"
  },
  dialogTitle: {
    borderTop: "1px solid ##E0E0E0",
    backgroundColor: "#444444",
    color: COLORS.WHITE,
    height: "50px"
  },
  dialogTitleText: {
    fontSize: STYLE_CONSTS.FONT_LARGE
  },
  dialogSubTitleText: {
    fontSize: "13px",
    paddingTop: "30px"
  },
  titleContainer: {
    width: "504px",
    padding: "33px 44px 11px 36px"
  },
  boldText: {
    textTransform: "capitalize",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM
  },
  columnHeaderButton: {
    borderRadius: "none",
    fontFamily: "inherit",
    fontWeight: "700",
    fontSize: "inherit",
    padding: "0.5em",
    marginBottom: "1px"
  },
  buttonDescending: {
    content: "👇",
    border: "0",
    borderRadius: "none",
    fontFamily: "inherit",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_BOLD,
    fontSize: "inherit",
    padding: "0.5em",
    marginBottom: "1px"
  },

  cardTitle: {
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    fontSize: "20px"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    marginBottom: 20,
    color: COLORS.COOL_GREY,
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "33px 44px 11px 36px"
  },
  formTitle: {
    textTransform: "capitalize",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    fontSize: STYLE_CONSTS.FONT_REGULAR,
    paddingBottom: "18px"
  },
  headerRow: {
    background: COLORS.NEAR_WHITE
  },
  input: {
    backgroundColor: COLORS.COOL_GREY,
    border: "none"
  },
  pageCard: {
    background: COLORS.WHITE,
    width: "1294px",
    height: "auto",
    padding: "37px",
    borderRadius: "20px"
  },
  pageTitle: {
    color: COLORS.COOL_GREY,
    fontWeight: "500",
    fontSize: "20px"
  },
  pageWrapper: {
    padding: "37px"
  },
  tableBtn: {
    textTransform: "capitalize",
    color: COLORS.MAIN_ORANGE,
    fontSize: STYLE_CONSTS.FONT_MEDIUM,
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    textAlign: "center"
  },
  smallMarginBottom: {
    marginBottom: 5
  },
  statusComplete: {
    textTransform: "capitalize",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    backgroundColor: COLORS.LIGHT_GREEN,
    borderRadius: "20px",
    width: "93px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center"
  },
  statusWaiting: {
    textTransform: "capitalize",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    backgroundColor: COLORS.LIGHT_YELLOW,
    borderRadius: "20px",
    width: "93px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textAlign: "center"
  },
  statusPending: {
    textTransform: "capitalize",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    backgroundColor: COLORS.LIGHT_YELLOW,
    borderRadius: "20px",
    width: "93px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textAlign: "center"
  },
  subTitleText: { fontSize: "13px", fontWeight: "300" },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1em"
  },
  th: {
    textAlign: "left",
    background: COLORS.NEAR_WHITE,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5
  },
  td: {
    padding: "0.5em"
  },
  validationErrorMessage: {
    color: "red",
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left"
  },
  marginLeft: {
    marginLeft: 5
  },
  inputField: {
    height: 45,
    paddingLeft: 10
  }
};

export default styles;
