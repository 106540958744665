import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import AddressFragment from "../../fragments/address/address.fragment";
import ConnectionFragment from "../../fragments/connections/connection-fragment";
import RoleRateFragment from "../../fragments/role-rate/role-rate.fragment";

export default addAuthVars(gql`
  ${RoleRateFragment}
  ${ConnectionFragment}
  ${AddressFragment}
  query getProfile(
    $connectionOffset: Int!
    $connectionLimit: Int!
    $isRequestPage: Boolean
  ) {
    user {
      id
      firstName
      lastName
      email
      phoneNumber
      photo
      pin
      canRecieveAllEmails
      usersMeta {
        applicantAlerts
        hourlyAlerts
      }
      venueIds
      homeVenues {
        id
        name
      }
      account {
        id
        currencyRegion
        customIdentifier
        contactPhone
        contactEmail
        constants
        totalOutstandingTimesheets
        logo
        defaultBreakMinutes
        policy {
          id
          minimumShiftLength
          minimumShiftPercentage
          cancellationPeriod
        }
        banner
        accountName
        currencyRegion
        isAutoAcceptEnabled
        isRequestAllEnabled
        isMemberAvailabilityEnabled
        isMinimumOnePartnerManagedConnection
        isPayCalculationOnCheckInOutTimes
        invoiceAddress {
          ...AddressFragment
        }
        postalAddress {
          ...AddressFragment
        }
        briefingTemplates(
          orderByColumn: "identifier"
          orderByDirection: "ASC"
        ) {
          id
          identifier
          content
          createdAt
        }
        outstandingTimesheets {
          id
          endTime
        }
        uniformTemplates {
          id
          identifier
          items
          createdAt
        }
        venues {
          id
          name
          address
          meetingPoint
          directions
          internalId
          lat
          lng
          createdAt
          isPinCodeCheckInEnabled
          isQrCodeCheckInEnabled
          subvenues {
            id
            name
            integraId
            mmCode
            costCode
            venueId
          }
        }
        lastUsedVenue {
          id
        }
        roleRates(accountType: requester, isRequestPage: $isRequestPage) {
          ...RoleRateFragment
        }
        requesterConnections: connections(
          accountType: requester
          offset: $connectionOffset
          limit: $connectionLimit
        ) {
          data {
            ...ConnectionFragment
          }
          offset
          limit
          totalResults
        }
        providerConnections: connections(
          accountType: provider
          offset: $connectionOffset
          limit: $connectionLimit
        ) {
          data {
            ...ConnectionFragment
          }
          offset
          limit
          totalResults
        }
        serviceAreas {
          id
          name
          path
        }
        manageableShiftsCount
        isProviderPayRateOverride
        divisions {
          id
          name
        }

        newTimesheetsStartDate
        isMemberAvailabilityEnabled
        isSleepTimeEnabled
      }
    }
  }
`);
