import React from "react";
import { useHistory } from "react-router-dom";

import ModalError from "~/src/components/modal-error";

export default () => {
  const history = useHistory();

  return (
    <ModalError
      title="404 - Not Found"
      buttonText="Go Home"
      onClose={() => history.push("/")}
    >
      We're sorry but that page was not found.
    </ModalError>
  );
};
