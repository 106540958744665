import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_EVOLVE_CLIENT_INFORMATION = gql`
  query getEvolveClientInformation($accountId: ID!) {
    evolveClientInformation(accountId: $accountId) {
      retroPercentage
      accountingCycles
      isParent
      parentId
      location
      department
      parents {
        id
        accountName
      }
      children {
        id
        accountName
      }
    }
  }
`;

export default graphql(GET_EVOLVE_CLIENT_INFORMATION, {
  props: handleErrors(({ data: { evolveClientInformation, loading } }) => ({
    evolveClientInformation,
    loading
  })),
  options: () => ({
    fetchPolicy: "network-only"
  })
});
