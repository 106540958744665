import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_EVOLVE_MEMBER_DATA = gql`
  mutation updateEvolveMemberData(
    $memberId: ID!
    $isCriminalConviction: Boolean
    $isFoodHygieneCertificate: Boolean
    $methodOfTransport: String
    $referralMethod: String
    $bankBranch: String
    $isStudentLoan: Boolean
    $isPostgraduateLoan: Boolean
    $employeeStatementOptions: String
    $phoneInternational: String
  ) {
    updateEvolveMemberData(
      memberId: $memberId
      isCriminalConviction: $isCriminalConviction
      isFoodHygieneCertificate: $isFoodHygieneCertificate
      methodOfTransport: $methodOfTransport
      referralMethod: $referralMethod
      bankBranch: $bankBranch
      isStudentLoan: $isStudentLoan
      isPostgraduateLoan: $isPostgraduateLoan
      employeeStatementOptions: $employeeStatementOptions
      phoneInternational: $phoneInternational
    ) {
      memberId
    }
  }
`;

export default graphql(UPDATE_EVOLVE_MEMBER_DATA, {
  name: "updateEvolveMemberData",
  props: ({ updateEvolveMemberData }) => ({
    updateEvolveMemberData: variables =>
      updateEvolveMemberData({
        variables,
        refetchQueries: ["getEvolveMemberData"]
      })
  })
});
