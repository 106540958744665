import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { parse } from "query-string";

import { UserInfoService } from "@teamrota/authlib";
import { OPENID_ISSUER, SPLASH_URL } from "~/src/config";

export const baseUrl = `${window.location.protocol}//${window.location.host}`;
export const loginPath = "Login";

// quickly read the value of the persist cookie now, unset -> true
const persist =
  document.cookie.match("(^|;)\\s*persist\\s*=\\s*([^;]+)")?.pop() !== "false";

export const userManager = new UserManager({
  userStore: new WebStorageStateStore({
    prefix: "rota.user.",
    store: persist ? localStorage : sessionStorage
  }),
  stateStore: new WebStorageStateStore({
    prefix: "rota.oidc.",
    store: sessionStorage
  }),
  authority: OPENID_ISSUER,
  client_id: process.env.OPENID_CLIENT_ID,
  prompt: "consent",
  scope: process.env.OPENID_SCOPES,
  response_type: "code",
  redirect_uri: `${baseUrl}/`,
  post_logout_redirect_uri: `${baseUrl}/`,
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: false
});

Log.level = Log.WARN;
Log.logger = {
  debug(...args) {
    console.debug(...args);
  },
  info(...args) {
    console.info(...args);
  },
  warn(...args) {
    console.warn(...args);
  },
  error(...args) {
    console.error(...args);
  }
};

export const userInfoService = new UserInfoService(OPENID_ISSUER);

export const signinRedirect = async (pathname, noSplash) => {
  if (pathname && pathname !== `/${loginPath}` && !noSplash) {
    // trigger the login only if explicitly in the login flow
    // otherwise go to splash screen to avoid timeouts
    window.location.href = `${SPLASH_URL}?url=${baseUrl}/${loginPath}`;
    return;
  }

  const extraQueryParams = {};

  // read actas and add as a extraQueryParams
  // this allows support to sign in with other accounts in God mode
  const params = parse(window.location.search);
  if (params.actas) {
    extraQueryParams.actas = params.actas;
  }

  userManager.signinRedirect({ prompt: "login consent", extraQueryParams });
};

// refreh call has to be guarded so we don't initiate the same call
// to the token endpoint with the same refresh token while the first
// caller is awaiting the response to that call

let refreshInProgress = null;
export const refreshUser = async (attempts = 1) => {
  if (refreshInProgress) {
    return await refreshInProgress;
  }

  refreshInProgress = (async () => {
    for (let attempt = 0; attempt < attempts; attempt++) {
      try {
        const user = await userManager.getUser();
        if (user !== null && user.refresh_token) {
          await userManager.signinSilent();
          return userManager.getUser();
        }
      } catch (err) {
        console.log(err); // could not refresh

        if (attempt < attempts - 1) {
          // expo backoff, 250ms * 2^(0,1,2,3)
          const delay = 250 * Math.pow(2, attempt);
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      } finally {
        refreshInProgress = null;
      }
    }

    return null;
  })();

  return await refreshInProgress;
};

userManager.events.addAccessTokenExpiring(refreshUser);
userManager.events.addAccessTokenExpired(refreshUser);

export const getCurrentAccessToken = async () => {
  const user = await userManager.getUser();
  if (user != null) {
    if (user.expires_in < 10) {
      // since this is last ditch, do a retry
      // making multiple attempts in this case
      const refreshedUser = await refreshUser(5);
      if (refreshedUser) {
        return refreshedUser.access_token;
      }
    } else {
      return user.access_token;
    }
  }

  return null;
};
