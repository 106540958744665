import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { flow } from "lodash";

import update from "lodash/fp/update";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

const LIMIT = 10;

const GET_PAY_PERIODS = addAuthVars(gql`
  query getPayPeriods($memberId: ID!, $offset: Int!, $limit: Int!) {
    account {
      id
      member(id: $memberId) {
        id
        accounts {
          id
          isRota
        }
        totalEarnings
        totalPayPeriods
        payPeriods(offset: $offset, limit: $limit) {
          totalResults
          offset
          data {
            dateRange
            id
            noOfShifts
            payDay
            predicted
            totalHours
            totalPay
          }
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_PAY_PERIODS, {
    props: handleErrors(({ data: { fetchMore, loading, account } }) => ({
      isLoading: loading,
      member: account?.member,
      fetchMorePeriods: offset => loadMore(offset, fetchMore)
    })),
    options: ({ auth, memberId, offset }) => ({
      variables: auth.addVals(GET_PAY_PERIODS, {
        memberId,
        offset: offset || 0,
        limit: LIMIT
      })
    })
  }),
  withAuth
);

export function loadMore(offset, fetchMore) {
  return fetchMore({
    variables: { offset },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      update(
        "account.member.payPeriods.data",
        current => [
          ...current,
          ...(fetchMoreResult?.account?.member?.payPeriods?.data ?? [])
        ],
        previousResult
      )
  });
}
