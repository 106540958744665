import React, { Fragment } from "react";
import styled from "styled-components";

export const ValueCell = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.text.primary};
  font-size: 16px;
`;

export const LabelCell = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
`;

export const StyledGrid = styled.div`
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 32px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding-bottom: 12px;
  overflow: auto;
`;

export const StyledWrapSubtitle = styled.div`
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-top-color: transparent;
  border-left-color: transparent;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const UncalculatedRoleRateDetails = ({ uncalculatedRoleRates }) => {
  return (
    <StyledGrid>
      <StyledWrapSubtitle>Rate Info</StyledWrapSubtitle>
      <LabelCell>Pay Type</LabelCell>
      <LabelCell>Pay Rate</LabelCell>
      <LabelCell>Charge Rate</LabelCell>
      <LabelCell>Applicable</LabelCell>

      {uncalculatedRoleRates.map(uncalculatedRoleRate => {
        return (
          <Fragment key={uncalculatedRoleRate.id}>
            <LabelCell>{uncalculatedRoleRate.name}</LabelCell>
            <ValueCell>
              {uncalculatedRoleRate?.isDayRate ? "Daily" : "Hourly"}
            </ValueCell>
            <ValueCell>{uncalculatedRoleRate?.payRate}</ValueCell>
            <ValueCell>{uncalculatedRoleRate?.chargeRate}</ValueCell>
            <ValueCell>{uncalculatedRoleRate?.applicable || "-"}</ValueCell>
          </Fragment>
        );
      })}
    </StyledGrid>
  );
};

export default UncalculatedRoleRateDetails;
