import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";

import styles, {
  Form,
  Field,
  Label,
  Input,
  Select,
  Button,
  Row,
  Content
} from "./styles";
import { RotaSnackBar } from "@teamrota/rota-design";

import getEvolveMemberData from "./graphql/get-member-evolve-data";
import updateEvolveMemberData from "./graphql/update-member-evolve-data";

import { BOOLEAN_OPTIONS } from "~/src/consts";

const EMPLOYEE_STATEMENT_OPTIONS = ["Option A", "Option B", "Option C"];

const workingOptions = {
  "Option 1": true,
  "Option 2": false
};

const EvolveInfo = ({
  memberId,
  evolveMemberData,
  loading,
  updateEvolveMemberData
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [isErrors, setIsErrors] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: evolveMemberData
  });

  const watchEmployeeStatementOptions = watch("employeeStatementOptions");

  useEffect(() => {
    const data = { ...evolveMemberData };
    data.signatureDate = moment(data.signatureDate).format("DD/MM/YYYY");
    data.agreeWorkingTimesOptions =
      workingOptions[data.agreeWorkingTimesOptions];
    reset(data);
  }, [evolveMemberData]);

  const onSubmit = async data => {
    setIsUpdating(true);
    const updateData = {
      isCriminalConviction: data.isCriminalConviction,
      isFoodHygieneCertificate: data.isFoodHygieneCertificate,
      methodOfTransport: data.methodOfTransport,
      referralMethod: data.referralMethod,
      bankBranch: data.bankBranch,
      agreeWorkingTimesOptions: data.agreeWorkingTimesOptions,
      isStudentLoan: data.isStudentLoan,
      isPostgraduateLoan: data.isPostgraduateLoan,
      employeeStatementOptions: data.employeeStatementOptions,
      phoneInternational: data.phoneInternational
    };
    try {
      await updateEvolveMemberData({
        memberId,
        ...updateData
      });
    } catch (e) {
      setIsErrors(true);
    } finally {
      setIsUpdating(false);
      setIsSnackOpen(true);
    }
  };

  return (
    !loading && (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Field>
              <Label>Unspent Criminal Convictions</Label>
              <Select {...register("isCriminalConviction")}>
                {BOOLEAN_OPTIONS.map(({ value, label }, i) => (
                  <option key={i} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <p>{errors.isCriminalConviction?.message}</p>
            </Field>
            <Field>
              <Label>Basic Food Hygiene Certificate</Label>
              <Select {...register("isFoodHygieneCertificate")}>
                {BOOLEAN_OPTIONS.map(({ value, label }, i) => (
                  <option key={i} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <p>{errors.department?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Method of Transport to work</Label>
              <Input {...register("methodOfTransport")} />
              <p>{errors.methodOfTransport?.message}</p>
            </Field>
            <Field>
              <Label>How Did You Hear About Us?</Label>
              <Input {...register("referralMethod")} />
              <p>{errors.methodOfTransport?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Bank Branch</Label>
              <Input {...register("bankBranch")} />
              <p>{errors.methodOfTransport?.message}</p>
            </Field>
            <Field>
              <Label>Telephone (International)</Label>
              <Input {...register("phoneInternational")} />
              <p>{errors.phoneInternational?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("agreeWorkingTimesOptions")}
                />
                48 Hour Working Agreement{" "}
              </Label>
              <p>{errors.agreeWorkingTimesOptions?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isStudentLoan")}
                />
                Student Loan{" "}
              </Label>
              <p>{errors.isStudentLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isPostgraduateLoan")}
                />
                PostGraduate Loan{" "}
              </Label>
              <p>{errors.isPostgraduateLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Employee Statement</Label>
              <Select {...register("employeeStatementOptions")}>
                {EMPLOYEE_STATEMENT_OPTIONS.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
              <p>{errors.employeeStatementOptions?.message}</p>
            </Field>
          </Row>
          <Row>
            {watchEmployeeStatementOptions === "Option A" && (
              <Content>
                <p>
                  A - Do not apply this statement if you are in receipt of a
                  State, Works or Private Pension.
                </p>
                Apply this statement if the following applies. This is your
                first job since 6 April and since the 6 April if you have not
                received any payments from any of the following.
                <ul>
                  <li>Universal Credit/JobSeekers Allowance</li>
                  <li>Employment and Support Allowance</li>
                  <li>PIP/Incapacity Benefit/DLA</li>
                </ul>
              </Content>
            )}
            {watchEmployeeStatementOptions === "Option B" && (
              <Content>
                <p>
                  B - Do not apply this statement if you are in receipt of a
                  State, Works or Private Pension.
                </p>
                Choose this statement if the following applies. Since the 6
                April if you have had another job, but you do not have a P45.
                And/or since the 6 April you hace received payments from any of
                the following:
                <ul>
                  <li>Universal Credit/JobSeekers Allowance</li>
                  <li>Employment and Support Allowance</li>
                  <li>PIP/Incapacity Benefit/DLA</li>
                </ul>
              </Content>
            )}
            {watchEmployeeStatementOptions === "Option C" && (
              <Content>
                <p>C - Choose this statement if:</p>
                <ul>
                  <li>You have had another job and/or</li>
                  <li>
                    You are in receipt of a State, Works or Private Pension.
                  </li>
                </ul>
              </Content>
            )}
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  disabled
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isKeyInformationDocumentAccepted")}
                />
                Accepted Key Information Document{" "}
              </Label>
              <p>{errors.isStudentLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  disabled
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isTermsOfEngagementAccepted")}
                />
                Accepted Terms of Engagement{" "}
              </Label>
              <p>{errors.isPostgraduateLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  disabled
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isMedicalStatementAccepted")}
                />
                Accepted Medical Statement{" "}
              </Label>
              <p>{errors.isStudentLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  disabled
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isUniformStatementAccepted")}
                />
                Accepted Uniform Statement{" "}
              </Label>
              <p>{errors.isPostgraduateLoan?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>
                <input
                  disabled
                  style={styles.Checkbox}
                  type="checkbox"
                  {...register("isGDPRConsentFormAgreed")}
                />
                Agreed GDPR Consent Form{" "}
              </Label>
              <p>{errors.isGDPRConsentFormAgreed?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Signed Name</Label>
              <Input disabled {...register("signatureFullName")} />
              <p>{errors.signatureFullName?.message}</p>
            </Field>
            <Field>
              <Label>Signed Date</Label>
              <Input disabled {...register("signatureDate")} />
              <p>{errors.signatureDate?.message}</p>
            </Field>
          </Row>
          <Row noPadding>
            <Button disabled={isUpdating} type="submit">
              Save
            </Button>
          </Row>
        </Form>

        <RotaSnackBar
          snackOpen={isSnackOpen}
          onClose={() => setIsSnackOpen(false)}
          message={isErrors ? "Error" : "Success"}
          severity={isErrors ? "error" : "success"}
        />
      </>
    )
  );
};

export default updateEvolveMemberData(getEvolveMemberData(EvolveInfo));
