import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 320px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
  color: ${COLORS.COOL_GREY};
`;

const Input = styled.input`
  width: 231px;
  height: 38px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
`;

const Select = styled.select`
  appearance: none;
  width: 231px;
  height: 38px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
`;

const Button = styled.button`
  height: 36px;
  padding: 0 36px;
  border-radius: 36px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  background: ${({ disabled, theme }) =>
    disabled ? COLORS.PALE_GREY : theme.primary.main};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 10px")};
`;

const Content = styled.div`
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  color: #939ba7;
`;

const styles = {
  Checkbox: {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle"
  }
};

export {
  Form,
  Field,
  Input,
  Select,
  Row,
  Label,
  Button,
  Content,
  styles as default
};
