import styled from "styled-components";
import Icon from "~/src/components/icon";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.text.secondary};
`;

const Input = styled.input`
  width: 231px;
  height: 38px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
`;

const Select = styled.select`
  appearance: none;
  width: 231px;
  height: 38px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
`;

const Button = styled.button`
  height: 36px;
  padding: 0 36px;
  border-radius: 36px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  background: ${({ disabled, theme }) =>
    disabled ? theme.neutral.light : theme.primary.main};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 10px")};
`;

const List = styled.ul`
  margin: 0px;
  padding-left: 20px;
  width: 300px;
  min-height: 150px;
  max-height: calc(100vh - 800px);
  overflow-y: scroll;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
`;

const Span = styled.span`
  max-width: 200px;
`;

const StyledIcon = styled(Icon)`
  margin: 0px 5px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;

const styles = {
  Checkbox: {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle"
  }
};

export {
  Form,
  Field,
  Input,
  Select,
  Row,
  Label,
  Button,
  List,
  ListItem,
  StyledIcon,
  Span,
  styles as default
};
