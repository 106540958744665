import moment from "moment-timezone";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";

import { isValidDate } from "~/src/utils/validators";

const getIfDateValid = dateStr =>
  dateStr && isValidDate(dateStr)
    ? moment(dateStr, "DD/MM/YYYY").toString()
    : null;

const getMemberVariables = ({
  memberSearchText,
  selectedFilters,
  selectedFilterOptions
}) => {
  const getFilterProp = path => get(path, selectedFilters) || undefined;

  return {
    searchText: memberSearchText,
    minRating:
      getFilterProp("rating") &&
      (get("rating.min", selectedFilterOptions) || 1),
    maxRating:
      getFilterProp("rating") &&
      (get("rating.max", selectedFilterOptions) || 6),
    roleNames:
      getFilterProp("roles") &&
      getOr([], "roles", selectedFilterOptions).map(role => role.name),
    serviceAreaId:
      getFilterProp("service-area") &&
      get("service-area.id", selectedFilterOptions),
    stateIn:
      getFilterProp("status") &&
      getOr([], "status", selectedFilterOptions).map(({ id }) => id),
    workedWithPartnersIn:
      getFilterProp("worked-with") &&
      getOr([], "worked-with", selectedFilterOptions).map(({ id }) => id),
    bookingStateIn:
      getFilterProp("booking-states") &&
      getOr([], "booking-states", selectedFilterOptions).map(({ id }) => id),
    // booking state dates
    bookingStateStartTime:
      getFilterProp("booking-states-between") &&
      getIfDateValid(
        get(["booking-states-between", "startTime"], selectedFilterOptions)
      ),
    bookingStateEndTime:
      getFilterProp("booking-states-between") &&
      getIfDateValid(
        get(["booking-states-between", "endTime"], selectedFilterOptions)
      ),
    bookingStateNotBetweenStartTime:
      getFilterProp("booking-states-not-between") &&
      getIfDateValid(
        get(["booking-states-not-between", "startTime"], selectedFilterOptions)
      ),
    bookingStateNotBetweenEndTime:
      getFilterProp("booking-states-not-between") &&
      getIfDateValid(
        get(["booking-states-not-between", "endTime"], selectedFilterOptions)
      )
  };
};

export default getMemberVariables;
