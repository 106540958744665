import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { flow } from "lodash";
import moment from "moment-timezone";

import { CANCELLATION_REASONS } from "~/src/consts";
import Button from "~/src/components/button";
import { errorModal } from "~/src/utils/errors";
import { getPolicyQueryParams } from "~/src/utils/formatting";
import asyncConfirm from "~/src/utils/async-confirm";
import updateShiftState from "~/src/graphql/mutations/update-shift-state/update-shift-state.decorator";
import Modal from "~/src/components/modal";
import stopAlgoDelay from "~/src/graphql/mutations/stop-algo-delay";
import { showMemberDetails } from "~/src/containers/pools/reducer";

import getShiftGroup from "~/src/containers/requester-calendar/shift-modal/graphql/get-shift-group/get-shift-group.query";
import ShiftDetails from "~/src/containers/requester-calendar/shift-modal/components/shift-details";
import { ModalInner } from "~/src/containers/requester-calendar/shift-modal/shift-modal.styles";
import EditShift from "~/src/containers/requester-calendar/shift-modal/components/edit-shift";
import getAllRoles from "~/src/containers/requester-calendar/shift-modal/graphql/get-all-roles";
import createOrUpdateShiftFn from "~/src/containers/requester-calendar/graphql/create-or-update-shift";
import handleEditsWithinCancellationPolicyPeriod from "~/src/containers/requester-calendar/shift-modal/handle-edits-within-cancellation-policy-period";
import {
  parseShiftToForm,
  parseToSavableData
} from "~/src/containers/requester-calendar/shift-modal/utils";

import {
  updateFormState,
  onModalClose,
  setFormState,
  onUpdateUniform
} from "~/src/containers/requester-calendar/shift-modal/reducer";

import {
  createVenueOptions,
  createUniformOptions,
  createBriefingOptions,
  createRoleRateOptions,
  getIsPolicyApplied,
  getHours,
  getRoleRates,
  createSubvenueOptions
} from "~/src/containers/requester-calendar/utils";

import { clearBonusState } from "~/src/containers/requester-calendar/reducer";

import getShiftDetails from "./graphql/GetShiftDetails";
import useAuth from "~/src/auth/hooks/use-auth";
import { Role } from "@teamrota/authlib";
import { useHomeVenues } from "../use-home-venues";
import { useShiftSubvenue } from "./graphql/use-shift-subvenue";

import { useStore } from "~/src/useStore";

const ShiftEditModal = props => {
  const {
    policy,
    totalInGroup,
    shiftId,
    isLoadingShift,
    shift,
    warningFields,
    shiftForm,
    user,
    targetAccountId,
    dispatch,
    onClose,
    createOrUpdateShift,
    updateShiftState,
    stopAlgoDelay,
    isProviderScheduler,
    partners
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingEdits, setIsSavingEdits] = useState(false);
  const [isSavedEdits, setIsSavedEdits] = useState(false);
  const [isCancellingShift, setIsCancellingShift] = useState(false);
  const [isOpeningShift, setIsOpeningShift] = useState(false);
  const [shiftOpened, setShiftOpened] = useState(false);

  const replacementBookings = useStore(state => state.replacementBookings);

  const auth = useAuth();

  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);

  const { homeVenues, accountId } = useHomeVenues();
  const { subvenue, loadingSubvenue } = useShiftSubvenue({ shiftId });

  const homeVenueIds = homeVenues.map(venue => venue.id);

  useEffect(() => {
    if (user && shift?.id && !loadingSubvenue) {
      dispatch(
        setFormState(
          parseShiftToForm({
            user,
            shift: {
              ...shift,
              subvenueId: subvenue?.id
            }
          })
        )
      );
    }
  }, [shift, loadingSubvenue, subvenue]);

  const handleClose = () => {
    onClose();
    dispatch(onModalClose());
    dispatch(clearBonusState());
    setTimeout(() => {
      setIsEditMode(false);
      setShiftOpened(false);
    }, 0);
  };

  const handleSaveEdits = async () => {
    const [shift] = shiftForm?.shifts;
    const hours = getHours(shift);
    const minimumShiftLength = policy?.minimumShiftLength;
    const minimumShiftPercentage = policy?.minimumShiftPercentage;

    const originalStartTime =
      shift?.originalStartTime || shift?.startTime || shift?.startTime;
    const originalEndTime =
      shift?.originalEndTime || shift?.endTime || shift?.endTime;

    const startIsSame = moment(shift?.startTime).isSame(
      moment(originalStartTime)
    );
    const endIsSame = moment(shift?.endTime).isSame(moment(originalEndTime));

    const timeIsDiff = !(startIsSame && endIsSame);

    if (
      timeIsDiff &&
      moment().isAfter(shift?.originalStartTime || shift?.startTime)
    ) {
      return errorModal("Cannot edit shift time after it has started");
    }

    const errorMessage = handleEditsWithinCancellationPolicyPeriod(
      { ...shift, originalStartTime, originalEndTime },
      policy
    );

    if (errorMessage) {
      return errorModal(errorMessage);
    }

    const needsWarning =
      timeIsDiff ||
      shift?.briefing !== shift?.briefing ||
      shift?.venue?.id !== shift?.venueId ||
      (shiftForm?.uniformChanges || []).includes(shift?.id);

    const hasBeenWarned =
      !needsWarning ||
      (await asyncConfirm("Fulfilment alert", {
        subComponent: () => (
          <span>
            {`The change you're about to make to this shift may result in accepted members cancelling off of the shift. Are you sure you want to make this change?`}
          </span>
        ),
        confirmButtonText: "Proceed",
        falseButtonText: "Back"
      }));

    if (hasBeenWarned) {
      if (
        !getIsPolicyApplied(policy, shift) ||
        (await asyncConfirm("Are you sure you want to edit this shift?", {
          subComponent: () => (
            <span>
              The shift length is {hours} hours so a minimum of{" "}
              {minimumShiftLength} hours or {minimumShiftPercentage}% will be
              charged in accordance with our{" "}
              <a href={getPolicyQueryParams(policy)} target="_blank">
                Minimum Shift Length Policy.
              </a>
            </span>
          ),
          confirmButtonText: "Save",
          falseButtonText: "Back"
        }))
      ) {
        setIsSavingEdits(true);
        setIsSavedEdits(false);
        const savableShift = {
          shouldSkipTimeValidation: true,
          ...parseToSavableData(shiftForm, replacementBookings)
        };

        try {
          await createOrUpdateShift(savableShift);

          setIsSavingEdits(false);
          setIsSavedEdits(true);
          await Button.successDelay();
          handleClose();
        } catch (e) {
          errorModal(e);
          setIsSavingEdits(false);
          setIsSavedEdits(false);
        }
      }
    }

    return undefined;
  };

  const handleCancelShift = async () => {
    const hoursFromShift = moment(shift.startTime).diff(
      moment(),
      "hours",
      true
    );
    const period = policy?.cancellationPeriod;
    const length = policy?.minimumShiftLength;
    const percertage = policy?.minimumShiftPercentage;
    const isCancellationPolicyApplied = policy && hoursFromShift < period;
    if (totalInGroup > 1) {
      const confirmPopUp = await asyncConfirm(
        "Are you sure you want to cancel this shift?",
        {
          confirmButtonText: "Cancel individual shift",
          falseButtonText: "Back",
          cancelEntireLinkedShift: "Cancel future shifts",
          isLinkedShift: true,
          callback: () => {},
          dropDownOptions: Object.values(CANCELLATION_REASONS).map(reason => {
            return { value: reason, label: reason };
          }),
          subComponent: isCancellationPolicyApplied
            ? () => (
                <span>
                  Only future shifts in this linked shift will be cancelled. Any
                  shifts cancelled less than {period} hours before the shift
                  start and so a minimum of {length} hours or {percertage}% will
                  be charged in accordance with our{" "}
                  <a href={getPolicyQueryParams(policy)} target="_blank">
                    Minimum Shift Length Policy.
                  </a>
                </span>
              )
            : null,
          hasMarginBottom: true
        }
      );

      if (
        ["cancelledIndividualLinkedShift", "cancelledShift"].includes(
          confirmPopUp.type
        )
      ) {
        setIsCancellingShift(true);
        try {
          await updateShiftState(
            shift?.id,
            confirmPopUp.type === "cancelledIndividualLinkedShift"
              ? confirmPopUp.type
              : "cancelled",
            confirmPopUp.reason
          );
          setIsEditMode(false);
          setIsCancellingShift(false);
        } catch (e) {
          errorModal(e);
          setIsCancellingShift(false);
        }
      }
    } else {
      const ret = await asyncConfirm(
        "Are you sure you want to cancel this shift?",
        {
          confirmButtonText: "Cancel shift",
          falseButtonText: "Back",
          dropDownOptions: Object.values(CANCELLATION_REASONS).map(reason => {
            return { value: reason, label: reason };
          }),
          callback: () => {},
          subComponent: isCancellationPolicyApplied
            ? () => (
                <span>
                  The shift is being cancelled less than {period} hours before
                  the shift start and so a minimum of {length} hours or{" "}
                  {percertage}% will be charged in accordance with our{" "}
                  <a href={getPolicyQueryParams(policy)} target="_blank">
                    Minimum Shift Length Policy.
                  </a>
                </span>
              )
            : null,
          hasMarginBottom: true
        }
      );
      if (ret) {
        setIsCancellingShift(true);
        try {
          await updateShiftState(shift?.id, "cancelled", ret);
          setIsEditMode(false);
          setIsCancellingShift(false);
        } catch (e) {
          errorModal(e);
          setIsCancellingShift(false);
        }
      }
    }
  };

  const handleDeleteDraftShift = async () => {
    const ret = await asyncConfirm(
      "Are you sure you want to delete this draft?",
      {
        confirmButtonText: "Delete draft",
        falseButtonText: "Back",
        callback: () => {},
        hasMarginBottom: true
      }
    );
    if (ret) {
      setIsCancellingShift(true);
      try {
        await updateShiftState(shift?.id, "draftDeleted");
        setIsCancellingShift(false);
        handleClose();
        setIsEditMode(false);
      } catch (e) {
        errorModal(e);
        setIsCancellingShift(false);
      }
    }
  };

  const handleOpenShift = async () => {
    if (
      await asyncConfirm("Are you sure you want to open this shift?", {
        confirmButtonText: "Open shift",
        falseButtonText: "Back",
        isConfirmButtonGreen: true,
        hasMarginBottom: true
      })
    ) {
      setIsOpeningShift(true);
      try {
        await stopAlgoDelay(shift?.id);
        setIsOpeningShift(false);
        setShiftOpened(true);
      } catch (e) {
        errorModal(e);
        setIsOpeningShift(false);
      }
    }
  };

  if (!shiftId) return false;

  const roleRates = getRoleRates(user?.account?.roleRates || [], shift || {});

  const roleRateOptions = createRoleRateOptions({
    roleRates: user?.account?.roleRates || [],
    accountId: "",
    shift: shift || {}
  });

  const briefingOptions = createBriefingOptions(
    user?.account?.briefingTemplates || []
  );

  const venues =
    user?.venueIds?.length > 0
      ? user?.account?.venues.filter(({ id }) =>
          user?.venueIds.includes(parseInt(id))
        )
      : user?.account?.venues;

  const selectedVenueId = shiftForm?.shifts?.[0]?.venueId;

  const subvenues = venues.find(venue => venue.id === selectedVenueId)
    ?.subvenues;

  const selfProviderVenues = venues.filter(venue =>
    homeVenueIds.includes(venue.id)
  );

  const venueOptions = createVenueOptions(
    isSelfProvider && targetAccountId === accountId
      ? selfProviderVenues || []
      : venues || []
  );

  const subvenueOptions = createSubvenueOptions(subvenues || []);

  const uniformOptions = createUniformOptions(
    user?.account?.uniformTemplates || []
  );

  const alreadyExistingBonuses = shift?.bonuses
    ? shift.bonuses.filter(bonus => bonus?.type === "bonus")
    : [];

  const onEdit = () => setIsEditMode(true);

  const onBack = () => setIsEditMode(false);

  const showMember = id => dispatch(showMemberDetails(id));

  const onFormUpdate = shiftFormState =>
    dispatch(updateFormState(shiftFormState));

  return (
    <Modal
      onClose={handleClose}
      isLoading={Boolean(isLoadingShift)}
      isOpen={Boolean(shiftId)}
    >
      <ModalInner>
        <ShiftDetails
          isVisible={!isEditMode}
          shift={shift}
          onEdit={onEdit}
          showMember={showMember}
        />
        <EditShift
          isVisible={isEditMode}
          policy={shift?.policy}
          isShiftCancelled={shift.cancelledAt}
          warningFields={warningFields || []}
          shiftFormState={shiftForm}
          onBack={onBack}
          roleRates={roleRates}
          roleRateOptions={roleRateOptions}
          venueOptions={venueOptions}
          subvenueOptions={subvenueOptions}
          briefingOptions={briefingOptions}
          uniformOptions={uniformOptions}
          initialShift={shift}
          isSaving={isSavingEdits}
          isSaved={isSavedEdits}
          isCancellingShift={isCancellingShift}
          isOpeningShift={isOpeningShift}
          onCancelShift={handleCancelShift}
          onOpenShift={handleOpenShift}
          onSave={handleSaveEdits}
          onFormUpdate={onFormUpdate}
          shiftOpened={shiftOpened}
          targetAccountId={targetAccountId}
          onUpdateUniform={id => dispatch(onUpdateUniform(id))}
          alreadyExistingBonuses={alreadyExistingBonuses}
          accountId={user?.account?.id}
          onDeleteDraftShift={handleDeleteDraftShift}
          isProviderScheduler={isProviderScheduler}
          partners={partners}
        />
      </ModalInner>
    </Modal>
  );
};

const mapStateToProps = state => ({
  shiftForm: state?.shiftModal?.formState,
  warningFields: state?.shiftModal?.warningFields
});

export default flow(
  connect(mapStateToProps),
  getShiftGroup,
  createOrUpdateShiftFn,
  updateShiftState,
  stopAlgoDelay,
  getAllRoles,
  getShiftDetails
)(ShiftEditModal);
