import getOr from "lodash/fp/getOr";
import RichText from "react-rte/lib/RichTextEditor";
import { createReducer, createAction } from "~/src/utils";

const DEFAULT_STATE = {
  memberSearchText: "",
  shiftSearchText: "",
  activeNotificationTypes: ["EMAIL"],
  messageBody: RichText.createValueFromString("Hi, here is a message.", "html"),
  subject: "",
  shiftId: undefined,
  recipientMembers: [],
  activeTab: "members",
  bookingsWithShiftId: "",
  selectedFilters: { status: true },
  selectedFilterOptions: {
    status: [
      { name: "Active", id: "ACTIVE" },
      { name: "Asleep", id: "ASLEEP" }
    ]
  },
  partnerQuery: ""
};

export const ON_SEARCH_MEMBER = "notifications/ON_SEARCH_MEMBER";

export const ON_SEARCH_SHIFT = "notifications/ON_SEARCH_SHIFT";

export const ON_NOTIFICATION_TYPE_TOGGLE =
  "notifications/ON_NOTIFICATION_TYPE_TOGGLE";

export const ON_COMPOSE_MESSAGE = "notifications/ON_COMPOSE_MESSAGE";

export const ON_UPDATE_RECIPIENTS = "notifications/ON_UPDATE_RECIPIENTS";

export const ON_UPDATE_ACTIVE_TAB = "notifications/ON_UPDATE_ACTIVE_TAB";

export const ON_ADD_SHIFT_BOOKINGS = "notifications/ON_ADD_SHIFT_BOOKINGS";

export const ON_RESET_REDUCER = "notifications/ON_RESET_REDUCER";

export const ON_FILTER_CHECK = "notifications/ON_FILTER_CHECK";
export const ON_FILTER_OPTION_ADD = "notifications/ON_FILTER_OPTION_ADD";
export const ON_FILTER_OPTION_DELETE = "notifications/ON_FILTER_OPTION_DELETE";
export const ON_FILTER_OPTION_UPDATE = "notifications/ON_FILTER_OPTION_UPDATE";
export const ON_DATE_UPDATE = "notifications/ON_DATE_UPDATE";
export const ON_RATING_UPDATE = "notifications/ON_RATING_UPDATE";

export const ON_PARTNER_QUERY_UPDATE = "notifications/ON_PARTNER_QUERY_UPDATE";

export const onSearchMember = createAction(ON_SEARCH_MEMBER);

export const onToggleNotificationType = createAction(
  ON_NOTIFICATION_TYPE_TOGGLE
);
export const onComposeMessage = createAction(ON_COMPOSE_MESSAGE);

export const onUpdateRecipients = createAction(ON_UPDATE_RECIPIENTS);

export const onUpdateActiveTab = createAction(ON_UPDATE_ACTIVE_TAB);

export const onSearchShift = createAction(ON_SEARCH_SHIFT);

export const onFilterCheck = createAction(ON_FILTER_CHECK);

export const onAddShiftBookings = createAction(ON_ADD_SHIFT_BOOKINGS);

export const onResetReducer = createAction(ON_RESET_REDUCER);

export const onFilterOptionAdd = createAction(ON_FILTER_OPTION_ADD);
export const onFilterOptionDelete = createAction(ON_FILTER_OPTION_DELETE);
export const onFilterOptionUpdate = createAction(ON_FILTER_OPTION_UPDATE);
export const onDateUpdate = createAction(ON_DATE_UPDATE);
export const onRatingUpdate = createAction(ON_RATING_UPDATE);

export const onUpdatePartnerQuery = createAction(ON_PARTNER_QUERY_UPDATE);

export default createReducer(
  {
    [ON_SEARCH_MEMBER]: (state, { payload }) => ({
      ...state,
      memberSearchText: payload
    }),
    [ON_SEARCH_SHIFT]: (state, { payload }) => ({
      ...state,
      shiftSearchText: payload
    }),
    [ON_NOTIFICATION_TYPE_TOGGLE]: (state, { payload }) => {
      const { activeNotificationTypes } = state;
      if (
        activeNotificationTypes.includes(payload) &&
        activeNotificationTypes.length > 1
      ) {
        return {
          ...state,
          activeNotificationTypes: activeNotificationTypes.filter(
            type => type !== payload
          )
        };
      }
      return {
        ...state,
        activeNotificationTypes: [...state.activeNotificationTypes, payload]
      };
    },
    [ON_COMPOSE_MESSAGE]: (state, { payload }) => ({
      ...state,
      subject: payload?.subject,
      messageBody: payload?.messageBody || state.messageBody,
      shiftId: payload?.shiftId
    }),
    [ON_UPDATE_RECIPIENTS]: (state, { payload }) => ({
      ...state,
      // If the member is already in the array remove them else add
      recipientMembers: state.recipientMembers.some(
        mem => mem.id === payload.id
      )
        ? state.recipientMembers.filter(mem => mem.id !== payload.id)
        : [...state.recipientMembers, payload]
    }),
    [ON_UPDATE_ACTIVE_TAB]: (state, { payload }) => ({
      ...state,
      activeTab: payload
    }),
    [ON_ADD_SHIFT_BOOKINGS]: (state, { payload }) => ({
      ...state,
      bookingsWithShiftId: payload
    }),
    [ON_FILTER_CHECK]: (state, { payload }) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...payload
      }
    }),
    [ON_FILTER_OPTION_ADD]: (state, { payload }) => ({
      ...state,
      selectedFilterOptions: {
        ...state.selectedFilterOptions,
        [payload.name]: Array.from(
          new Set([
            ...getOr([], payload.name, state.selectedFilterOptions),
            payload.tag
          ])
        )
      }
    }),
    [ON_FILTER_OPTION_DELETE]: (state, { payload }) => {
      const namedFilterOptions = getOr(
        [],
        payload.name,
        state.selectedFilterOptions
      );
      return {
        ...state,
        selectedFilterOptions: {
          ...state.selectedFilterOptions,
          [payload.name]: [
            ...namedFilterOptions.slice(0, payload.index),
            ...namedFilterOptions.slice(
              payload.index + 1,
              payload.index + namedFilterOptions.length
            )
          ]
        }
      };
    },
    [ON_FILTER_OPTION_UPDATE]: (state, { payload }) => ({
      ...state,
      selectedFilterOptions: {
        ...state.selectedFilterOptions,
        [payload.name]: payload.value
      }
    }),
    [ON_RATING_UPDATE]: (state, { payload }) => ({
      ...state,
      selectedFilterOptions: {
        ...state.selectedFilterOptions,
        rating: {
          ...state.selectedFilterOptions.rating,
          [payload.name]:
            payload.value && Math.min(Math.max(payload.value, 1), 6)
        }
      }
    }),
    [ON_PARTNER_QUERY_UPDATE]: (state, { payload }) => ({
      ...state,
      partnerQuery: payload
    }),
    [ON_DATE_UPDATE]: (state, { payload }) => ({
      ...state,
      selectedFilterOptions: {
        ...state.selectedFilterOptions,
        [payload.name]: {
          ...state.selectedFilterOptions[payload.name],
          ...payload.value
        }
      }
    }),
    [ON_RESET_REDUCER]: () => ({
      ...DEFAULT_STATE
    })
  },
  DEFAULT_STATE
);
