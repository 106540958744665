import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Big from "big.js";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import {
  RotaButton,
  RotaModal,
  RotaTag,
  RotaSnackBar
} from "@teamrota/rota-design";
import PaymentInfo from "~/src/containers/roles/components/payment-info";
import DeleteModal from "~/src/components/new-pay-rate/delete-modal";
import { GET_ROLE_RATE } from "~/src/components/RateBreakdown/graphql";
import { UPDATE_ROLE_RATE } from "./graphql/update-role-rate";
import { DELETE_ROLE } from "~/src/containers/roles/graphql/delete-role";
import {
  StyledWrapModalEditTags,
  StyledWrapTagsTitle,
  StyledWrapTag
} from "./style";

export const ButtonWrapper = styled.span`
  width: 100%;
  padding: 16px 48px 48px;
`;

const getInitialValues = role => ({
  id: role?.id || null,
  defaultChargeRate: role?.chargeRate / 100 || 0,
  defaultPayRate: role?.payRate / 100 || 0,
  isOvertimeRate: role?.isOvertimeRate || false,
  isOverstayRate: role?.isOverstayRate || false,
  defaultOvertimePayRate: role?.overtimePayRate / 100 || 0,
  defaultOvertimeChargeRate: role?.overtimeChargeRate / 100 || 0,
  defaultOvertimeHoursLimit: role?.overtimeHoursLimit || 0,
  defaultOverstayPayRate: role?.overstayPayRate / 100 || 0,
  defaultOverstayChargeRate: role?.overstayChargeRate / 100 || 0,
  defaultOverstayHoursLimit: role?.overstayHoursLimit || 0
});

const getRateValue = rate =>
  Big(rate)
    .times(100)
    .toNumber();

const EditRole = ({
  setIsOpenEditRoleModal,
  selectedRoleRate,
  name,
  isDayRate,
  onClose
}) => {
  const { data } = useQuery(GET_ROLE_RATE, {
    variables: { roleRateId: selectedRoleRate?.id },
    skip: !selectedRoleRate?.id
  });
  const roleRate = data?.account?.roleRateById;

  const [updateRoleRate, { error }] = useMutation(UPDATE_ROLE_RATE, {
    refetchQueries: ["getRoleRate"]
  });

  const [deleteRole] = useMutation(DELETE_ROLE, {
    refetchQueries: ["getRoles"]
  });

  const [role, setRole] = useState({});
  const [snackbar, setSnackBar] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteRole = async id => {
    try {
      await deleteRole({
        variables: { id }
      });
      setIsDeleteModalOpen(false);
      setSnackBar({
        open: true,
        message: "Deleted successfully.",
        severity: "success"
      });
    } catch {
      setSnackBar({
        open: true,
        message: "Something went wrong. Please try again",
        severity: "error"
      });
      setIsDeleteModalOpen(false);
    }
  };

  const onCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: getInitialValues(roleRate),
    mode: "onChange"
  });

  useEffect(() => {
    reset(
      getInitialValues(roleRate),
      setRole({
        isOvertimeRate: roleRate?.isOvertimeRate,
        isOverstayRate: roleRate?.isOverstayRate
      })
    );
  }, [data, reset]);

  const handleRateTypeChange = ({ target: { name, checked } }) => {
    if (name === "isOvertimeRate" && checked) {
      setRole({
        isOverstayRate: false,
        isOvertimeRate: true
      });
    } else if (name === "isOverstayRate" && checked) {
      setRole({
        isOverstayRate: true,
        isOvertimeRate: false
      });
    } else {
      setRole({
        isOverstayRate: false,
        isOvertimeRate: false
      });
    }
  };

  const onSubmit = async data => {
    const variables = {
      roleRateId: selectedRoleRate?.id,
      payRate: getRateValue(data.defaultPayRate),
      chargeRate: getRateValue(data.defaultChargeRate),
      isOverstayRate: role.isOverstayRate,
      overstayPayRate: getRateValue(data.defaultOverstayPayRate),
      overstayChargeRate: getRateValue(data.defaultOverstayChargeRate),
      isOvertimeRate: role.isOvertimeRate,
      overtimePayRate: getRateValue(data.defaultOvertimePayRate),
      overtimeChargeRate: getRateValue(data.defaultOvertimeChargeRate),
      overstayHoursLimit: data?.defaultOverstayHoursLimit,
      overtimeHoursLimit: data?.defaultOvertimeHoursLimit
    };
    await updateRoleRate({ variables });
    if (error) {
      setSnackBar({
        open: true,
        message: "Something went wrong. Please try again",
        severity: "error"
      });
    } else {
      setSnackBar({
        open: true,
        message: "Saved successfully.",
        severity: "success"
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  return (
    <RotaModal name={name} onClose={() => setIsOpenEditRoleModal(false)}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PaymentInfo
            register={register}
            control={control}
            errors={errors}
            isRoleRatesModal
            initialIsDayRate={!!isDayRate}
            isOvertimeRate={role.isOvertimeRate}
            isOverstayRate={role.isOverstayRate}
            handleRateTypeChange={handleRateTypeChange}
          />
          <StyledWrapModalEditTags>
            <StyledWrapTagsTitle>Toggle tags</StyledWrapTagsTitle>
            <StyledWrapTag wrap="wrap">
              <RotaTag
                key={selectedRoleRate.id}
                tags={selectedRoleRate?.tags || []}
                isReadOnly
                styleTag={{
                  margin: "16px 16px 17px 0"
                }}
              />
            </StyledWrapTag>
            <ButtonWrapper>
              <RotaButton style={{ float: "right" }}>Save</RotaButton>
            </ButtonWrapper>
          </StyledWrapModalEditTags>
        </form>
        <RotaSnackBar
          snackOpen={snackbar.open}
          onClose={() => setSnackBar({})}
          message={snackbar.message}
          severity={snackbar.severity}
        />
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onCancel={() => onCancel()}
          deleteRoleName={name}
          handleDeleteRole={() => {
            handleDeleteRole(selectedRoleRate?.roleId);
          }}
          deleteRoleId={selectedRoleRate?.roleId}
        />
      )}
    </RotaModal>
  );
};
export default EditRole;
