import styled from "styled-components";
import { EVOLVE_THEME, STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const ButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 50px 0;
`;

export const CancelButton = styled.button`
  width: 200px;
  height: 40px;
  background-color: ${COLORS.GRID_GREY};
  color: ${({ theme }) => theme.text.primary};
  margin: 0 0 0 10px;
`;

export const BackToHomeButton = styled.button`
  width: 200px;
  height: 40px;
  background-color: ${COLORS.GRID_GREY};
  color: ${({ theme }) => theme.text.primary};
`;

export const ContainerDiv = styled.div`
  min-height: 100vh;
  margin: 0 20px;
`;

export const Div = styled.div`
  background-color: ${({ theme }) => theme.white};
  min-height: 100%;
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`;

export const Div_Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 0 20px 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: fit-content;
  background-color: ${COLORS.GRID_GREY};
  border: none;
  margin: ${({ margin }) => margin};
`;

export const Label = styled.label`
  max-width: 70vw;
  margin: ${({ margin }) => margin || "0 0 5px 0"};
`;

export const Link = styled.a`
  color: ${({ color }) => color || EVOLVE_THEME.PURPLE};
`;

export const P_header_mb = styled.p`
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 0 0 20px 0;
`;

export const Select = styled.select`
  background-color: ${COLORS.GRID_GREY};
  border: none;
  width: fit-content;
`;

export const SectionHeader = styled.p`
  border-color: black;
  border-style: solid;
  border-bottom-width: 1px;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
`;

export const Signature_Container = styled.div`
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
`;

export const Span = styled.span`
  margin: ${({ margin }) => margin};
`;

export const Span_MB20 = styled.span`
  margin: 0 0 20px 0;
`;

export const Span_Error = styled.span`
  color: red;
  margin: 5px 0 0 0;
`;

export const Span_Radio_Btn = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const SubmitButton = styled.button`
  width: 200px;
  height: 40px;
  background-color: ${({ isReadyToSubmit }) =>
    isReadyToSubmit ? EVOLVE_THEME.PURPLE : COLORS.GRID_GREY};
  color: white;
`;

export const TitleDiv = styled.div`
  margin: 0 0 30px 0;
  @media screen and (max-width: ${STYLE_CONSTS.MOBILE_DEVICE_MAX}) {
  }
`;

export const TitleText = styled.p`
  font-size: ${STYLE_CONSTS.FONT_REGULAR};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  @media screen and (max-width: ${STYLE_CONSTS.MOBILE_DEVICE_MAX}) {
  }
`;

export const PBold = styled.p`
  font-size: ${STYLE_CONSTS.FONT_REGULAR};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;
