import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_EVOLVE_MEMBER_DATA = gql`
  query getEvolveMemberData($memberId: ID!) {
    evolveMemberData(memberId: $memberId) {
      isCriminalConviction
      isFoodHygieneCertificate
      methodOfTransport
      referralMethod
      bankBranch
      agreeWorkingTimesOptions
      signatureFullName
      signatureDate
      isStudentLoan
      isPostgraduateLoan
      isKeyInformationDocumentAccepted
      isTermsOfEngagementAccepted
      isMedicalStatementAccepted
      isUniformStatementAccepted
      isGDPRConsentFormAgreed
      employeeStatementOptions
      phoneInternational
    }
  }
`;

export default graphql(GET_EVOLVE_MEMBER_DATA, {
  props: handleErrors(({ data: { evolveMemberData, loading } }) => ({
    evolveMemberData,
    loading
  })),
  options: {
    fetchPolicy: "network-only"
  }
});
