import styled from "styled-components";
import { COLORS, flexCentered, STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const useStyles = () => ({
  dialogPaper: {
    minHeight: "391px",
    overflowY: "hidden",
    width: "386px"
  }
});

const styles = {
  button: {
    margin: "14px 0 0 0",
    backgroundColor: COLORS.MAIN_ORANGE,
    color: COLORS.WHITE,
    width: "174px",
    height: "40px",
    borderRadius: "20px",
    marginBottom: "30px"
  },
  centered: {
    ...flexCentered,
    padding: "44px"
  },
  declineBtn: {
    backgroundColor: "#EBEBEB",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    color: COLORS.BLACK,
    width: "174px",
    height: "40px",
    borderRadius: "20px",
    margin: "0 0 14px 0"
  },
  dialogActions: {
    padding: "41px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  dialogContent: {
    height: "77px"
  },
  dialogText: {
    margin: "40px"
  },
  dialogTitle: {
    borderTop: "1px solid ##E0E0E0",
    backgroundColor: "#444444",
    color: COLORS.WHITE,
    height: "50px"
  },
  dialogTitleText: {
    fontSize: STYLE_CONSTS.FONT_LARGE,
    padding: "33px 44px 11px 44px",
    fontWeight: 500
  },
  iconButton: {
    color: COLORS.WHITE
  }
};

export default styles;
