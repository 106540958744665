import React, { createContext, useContext } from "react";
import { useQuery } from "@apollo/client";
import { currencySupport } from "@teamrota/rota-common";

import useAuth from "~/src/auth/hooks/use-auth";
import Money from "~/src/utils/money";
import GET_PROFILE from "~/src/graphql/queries/get-profile/get-profile.query";
import Spinner from "~/src/components/full-screen-spinner";

const ProfileContext = createContext({ user: null });
export const useProfile = () => {
  return useContext(ProfileContext);
};

export const useCurrency = () => {
  const { user } = useProfile();
  const { currencyCode, currencySymbol, taxRate } = user?.account;
  return { currencyCode, currencySymbol, taxRate };
};

/**
 * A few of our components aren't written in a way that properly waits for
 * the getProfile query to finish loading, so prime the cache with it here
 * and insert this higher up in the render tree
 */
const ProfileProvider = ({ children }) => {
  const auth = useAuth();

  const { loading, data } = useQuery(GET_PROFILE, {
    skip: !auth.authed,
    variables: auth.addVals(GET_PROFILE, {
      connectionOffset: 0,
      connectionLimit: 10
    }),
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  const user = data?.user;

  const currencyRegion = user?.account?.currencyRegion;
  const currencyCode = currencySupport[currencyRegion]?.code || "GBP";

  const currencySymbol = currencySupport[currencyRegion]?.symbol || "£";
  const taxRate = currencySupport[currencyRegion]?.vat || 0.2;

  Money.defaultCurrency = currencyCode;

  if (user?.account) {
    user.account = {
      currencySymbol,
      currencyCode,
      taxRate: taxRate * 100,
      ...user.account
    };
  }

  return loading ? (
    <Spinner />
  ) : (
    <ProfileContext.Provider value={{ user }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
