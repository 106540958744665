import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import getMemberVariables from "../utils/get-member-variables";

export default graphql(
  gql`
    mutation sendNotifications(
      $memberIds: [ID]!
      $isToAllMembers: Boolean
      $body: String!
      $bodyHTML: String
      $subject: String
      $notificationTypes: [notificationEnumType]!
      $searchText: String
      $minRating: Float
      $maxRating: Float
      $roleNames: [String]
      $serviceAreaId: ID
      $stateIn: [MemberStateType]
      $workedWithPartnersIn: [ID]
      $bookingStateIn: [String]
      $bookingStateStartTime: Date
      $bookingStateEndTime: Date
      $bookingStateNotBetweenStartTime: Date
      $bookingStateNotBetweenEndTime: Date
      $shiftId: ID
    ) {
      sendNotifications(
        memberIds: $memberIds
        isToAllMembers: $isToAllMembers
        body: $body
        bodyHTML: $bodyHTML
        notificationTypes: $notificationTypes
        subject: $subject
        searchText: $searchText
        minRating: $minRating
        maxRating: $maxRating
        roleNames: $roleNames
        serviceAreaId: $serviceAreaId
        stateIn: $stateIn
        workedWithPartnersIn: $workedWithPartnersIn
        bookingStateIn: $bookingStateIn
        bookingStateStartTime: $bookingStateStartTime
        bookingStateEndTime: $bookingStateEndTime
        bookingStateNotBetweenStartTime: $bookingStateNotBetweenStartTime
        bookingStateNotBetweenEndTime: $bookingStateNotBetweenEndTime
        shiftId: $shiftId
      )
      # Mutation returns VOID
    }
  `,
  {
    name: "sendNotifications",
    props: props => ({
      sendNotifications: ownProps =>
        props.sendNotifications({
          variables: {
            memberIds: ownProps.memberIds,
            isToAllMembers: ownProps.isToAllMembers,
            body: ownProps.body,
            subject: ownProps.subject,
            bodyHTML: ownProps.bodyHTML,
            notificationTypes: ownProps.notificationTypes,
            shiftId: ownProps.shiftId,
            ...getMemberVariables(ownProps)
          }
        })
    })
  }
);
