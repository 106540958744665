import React from "react";
import styled from "styled-components";
import { RotaButtonIcon, iconNew } from "@teamrota/rota-design";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";

import styles from "./slide-dialog/slide-dialog.styles";

const { Cancel } = iconNew;
const muistyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
});

const IconButton = styled(RotaButtonIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const DialogTitle = withStyles(muistyles)(props => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      id="dialog-title"
      disableTypography
      className={classes.root}
      {...other}
      style={styles.dialogTitle}
    >
      {onClose && (
        <IconButton onClick={onClose}>
          <Cancel />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
