import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

const RESEND_MEMBER_INVITE = gql`
  mutation resendMemberInvite($id: ID!) {
    resendMemberInvite(id: $id) {
      id
      sendCount
    }
  }
`;

export const resendMemberInvite = graphql(RESEND_MEMBER_INVITE, {
  name: "resendMemberInvite",
  props: ownProps => ({
    resendMemberInvite: ({ id }) =>
      ownProps.resendMemberInvite({
        variables: {
          id
        },
        refetchQueries: ["getMemberInvites"]
      })
  })
});

const DELETE_MEMBER_INVITE = gql`
  mutation deleteMemberInvite($id: ID!) {
    deleteMemberInvite(id: $id)
  }
`;

export const deleteMemberInvite = graphql(DELETE_MEMBER_INVITE, {
  name: "deleteMemberInvite",
  props: ownProps => ({
    deleteMemberInvite: ({ id }) =>
      ownProps.deleteMemberInvite({
        variables: {
          id
        },
        refetchQueries: ["getMemberInvites"]
      })
  })
});

export const GET_MEMBER_INVITES = gql`
  query getMemberInvites {
    memberInvites {
      id
      email
      firstName
      lastName
      status
      createdAt
      lastSent
      sendCount
      sentByUserEmail
    }
  }
`;

export default graphql(GET_MEMBER_INVITES, {
  name: "getMemberInvites",
  props: props => props
});
