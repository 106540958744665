import React, { useState, useEffect, useContext } from "react";
import { Role } from "@teamrota/authlib";
import { RotaModal, RotaButtonNew } from "@teamrota/rota-design";
import { useQuery } from "@apollo/client";

import { AuthContext } from "~/src/auth/contexts";
import { actAsAgent } from "~/src/auth/agent";
import { setConnections } from "~/src/utils/get-profile-utils";
import { formatDateMonthYear } from "~/src/utils/formatting";
import useAuth from "~/src/auth/hooks/use-auth";
import Tabs from "~/src/containers/modals/components/tabs";
import Title from "~/src/containers/modals/components/title";
import Divider from "~/src/containers/modals/components/divider";
import Text from "~/src/containers/modals/components/text";
import {
  LeftPane,
  RightPane
} from "~/src/containers/modals/components/content-wrapper";
import MemberDetails from "~/src/components/member-details";
import Partner from "~/src/components/partner";
import GET_PROFILE from "~/src/graphql/queries/get-profile/get-profile.query";

import ClientInfo from "../components/client-info";
import History from "../components/history";
import EditInfo from "./edit-info";
import EditPolicies from "./edit-policies";
import UserDocuments from "./user-documents";
import DeleteAlertDialog from "./alert-dialog";
import CalculatedUncalculatedRolesLists from "./edit-roles";
import UserVenues from "./user-venues";
import PartnerBreaks from "./partner-breaks";
import { useTargetAccount } from "./use-target-account";

import { FlexContainer, Scrollable, Spacer } from "./index.styles";

const ViewEditAccount = ({
  targetAccountId,
  isOpen,
  onClose,
  isPartner,
  partnerPayrollBreakId,
  setSelectedPartnerPayrollBreakId
}) => {
  if (!isOpen) return false;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { auth } = useContext(AuthContext);

  const [user, setUser] = useState("");

  const {
    targetAccount,
    calculatedRoles,
    uncalculatedRoles,
    uncalculatedRoleRates,
    loading,
    connectionPolicy,
    refetch: refetchTargetAccount
  } = useTargetAccount({
    accountId: targetAccountId
  });

  const { data: newUser } = useQuery(GET_PROFILE, {
    variables: auth.addVals(GET_PROFILE, {
      connectionLimit: 10,
      connectionOffset: 0
    })
  });

  useEffect(() => {
    if (newUser) {
      setUser(setConnections(newUser));
    }
  }, [newUser]);

  const setAuth = useAuth();

  const handleImpersonate = async ({ email }) => {
    await actAsAgent(email);
  };

  const roleRates = targetAccount?.roleRates?.map(roleRate => {
    roleRate?.tags?.concat(targetAccount?.tags);
    return roleRate;
  });

  const accountHasProviderPolicy = user?.account?.policy;

  const tabs = [
    {
      name: "Info",
      content: (
        <EditInfo
          account={targetAccount}
          matcherConfig={targetAccount?.matcherConfig}
          isPartner={isPartner}
        />
      )
    },
    {
      name: "Roles",
      content: (
        <CalculatedUncalculatedRolesLists
          accountId={targetAccountId}
          calculatedRoles={calculatedRoles}
          uncalculatedRoles={uncalculatedRoles}
          roleRates={roleRates}
          uncalculatedRoleRates={uncalculatedRoleRates}
          tags={targetAccount?.tags}
          isPartner={isPartner}
        />
      )
    },
    {
      name: "Venues",
      content: <UserVenues targetAccountId={targetAccountId} />
    },
    {
      name: "Breaks",
      content: (
        <PartnerBreaks
          targetAccountId={targetAccountId}
          partnerPayrollBreakId={partnerPayrollBreakId}
          setSelectedPartnerPayrollBreakId={setSelectedPartnerPayrollBreakId}
        />
      )
    },
    {
      name: "Team",
      content: (
        <Scrollable>
          <Spacer />
          {(targetAccount?.users ?? []).map(user => (
            <FlexContainer key={user.id}>
              <MemberDetails {...user} phone={user.phoneNumber} />
              <RotaButtonNew
                variant="outlined"
                onClick={() => handleImpersonate(user)}
              >
                Agent login
              </RotaButtonNew>
            </FlexContainer>
          ))}
        </Scrollable>
      )
    },
    {
      name: "Documents",
      content: (
        <UserDocuments
          targetAccountId={targetAccountId}
          documents={targetAccount?.documents?.concat(
            ...targetAccount?.s3Documents
          )}
          refetchTargetAccount={refetchTargetAccount}
        />
      )
    },
    ...(accountHasProviderPolicy
      ? [
          {
            name: "Policies",
            content: (
              <EditPolicies
                accountId={targetAccountId}
                policy={connectionPolicy}
              />
            )
          }
        ]
      : []),
    {
      name: "History",
      content: <History logs={targetAccount?.logs} />
    },
    ...(setAuth.hasRole(Role.EVOLVE)
      ? [
          {
            name: "Client Info",
            content: <ClientInfo accountId={targetAccountId} />
          }
        ]
      : [])
  ];

  return (
    <>
      <RotaModal
        onClose={onClose}
        isLoading={loading}
        name={targetAccount?.accountName}
        onDelete={() => setIsOpenDialog(true)}
        styles={{ minHeight: "90vh", height: "auto", paddingBottom: "100px" }}
      >
        <LeftPane>
          <Title marginBottom style={{ marginTop: "22px" }}>
            Summary
          </Title>
          <Partner
            id={targetAccount?.id}
            accountName={targetAccount?.accountName}
            logo={targetAccount?.logo}
          />
          <Divider width={270} />
          <Title marginTop>Other</Title>
          <Text>
            {`Created at: ${formatDateMonthYear(targetAccount?.createdAt)}`}
          </Text>
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
        {isOpenDialog && (
          <DeleteAlertDialog
            setIsOpenDialog={setIsOpenDialog}
            account={targetAccount}
            onClose={onClose}
          />
        )}
      </RotaModal>
    </>
  );
};

export default ViewEditAccount;
