import React, { useState } from "react";
import moment from "moment-timezone";
import { IconButton } from "@material-ui/core";

import getMemberInvites, {
  resendMemberInvite,
  deleteMemberInvite
} from "./graphql";

import AlertDialog from "../dialogs/alert-dialog/alert-dialog";
import styles from "../dialogs/slide-dialog/slide-dialog.styles";

const InvitesTable = ({
  getMemberInvites,
  resendMemberInvite,
  deleteMemberInvite
}) => {
  const memberInvites = getMemberInvites?.memberInvites;
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [snackOpen, setSnackOpen] = useState(false);
  const [resendInviteId, setResendInviteId] = useState(null);
  const [deleteInviteId, setDeleteInviteId] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const handleClickOpenResendDialog = (id, item) => {
    setFullName("");
    setOpenResendDialog(true);
    setResendInviteId(id);
    const name =
      item?.firstName && item?.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : "";
    setFullName(name);
  };

  const handleClickOpenDeleteDialog = (id, item) => {
    setFullName("");
    const name =
      item?.firstName && item?.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : "";
    setFullName(name);
    setOpenDeleteDialog(true);
    setDeleteInviteId(id);
  };

  const handleCloseAllDiaglogs = () => {
    setOpenDeleteDialog(false);
    setOpenResendDialog(false);
    setOpenSuccessDialog(false);
  };

  const confirmDeleteHandler = async () => {
    if (!isLoading && !!deleteInviteId) {
      setIsLoading(true);

      try {
        deleteMemberInvite({ id: deleteInviteId });
        setIsLoading(false);
        setDeleteInviteId(null);
        handleCloseAllDiaglogs();
        // setSnackOpen(true);
      } catch (error) {
        setIsLoading(false);
        // setSnackOpen(true);
        console.log("save error", error);
      }
    }
  };

  const confirmResendHandler = async () => {
    if (!isLoading && !!resendInviteId) {
      setIsLoading(true);

      try {
        resendMemberInvite({ id: resendInviteId });
        setIsLoading(false);
        setResendInviteId(null);
        handleCloseAllDiaglogs();
        // setSnackOpen(true);
        setOpenSuccessDialog(true);
      } catch (error) {
        setIsLoading(false);
        // setSnackOpen(true);
        console.log("save error", error);
      }
    }
  };

  return (
    <>
      <table style={styles.table}>
        <caption></caption>
        <thead>
          <tr style={styles.headerRow}>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Email</th>
            <th style={styles.th}>Status</th>
            <th style={{ ...styles.th, textAlign: "center" }}>First Sent</th>
            <th style={styles.th}>First Sent By</th>
            <th style={styles.th}>Last Sent</th>
            <th style={styles.th}>Send Count</th>
            <th
              style={{
                ...styles.th,
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px"
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {memberInvites?.map(item => (
            <tr key={item.id} style={styles.tr}>
              <td style={{ ...styles.td, ...styles.boldText }}>
                {`${item.firstName} ${item.lastName}`}{" "}
              </td>
              <td style={{ ...styles.td, textTransform: "lowercase" }}>
                {item?.email}
              </td>
              {item?.status === "Pending" && (
                <td style={{ ...styles.td, ...styles.statusPending }}>
                  {item?.status}
                </td>
              )}
              {item?.status === "Waiting" && (
                <td style={{ ...styles.td, ...styles.statusWaiting }}>
                  {item?.status}
                </td>
              )}
              {item?.status === "Complete" && (
                <td style={{ ...styles.td, ...styles.statusComplete }}>
                  {item?.status}
                </td>
              )}
              <td style={{ ...styles.td, textAlign: "center" }}>
                {moment(item?.createdAt).format("hh:mm MMM Do YYYY")}
              </td>
              <td style={styles.td}>{item?.sentByUserEmail}</td>
              <td style={{ ...styles.td, textAlign: "center" }}>
                {moment(item?.lastSent).format("hh:mm MMM Do YYYY")}
              </td>
              <td style={{ ...styles.td, ...styles.tableBtn }}>
                {item?.sendCount}
              </td>
              <td style={{ ...styles.td, ...styles.tableBtn }}>
                {item?.status !== "Complete" && (
                  <IconButton
                    style={styles.tableBtn}
                    type="text"
                    onClick={() => handleClickOpenResendDialog(item?.id, item)}
                    className={"sentBy"}
                  >
                    Resend
                  </IconButton>
                )}
                <IconButton
                  style={styles.tableBtn}
                  type="text"
                  onClick={() => handleClickOpenDeleteDialog(item?.id, item)}
                  className={"sentBy"}
                >
                  Delete
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AlertDialog
        setOpenDialog={setOpenResendDialog}
        openDialog={openResendDialog}
        handleClose={handleCloseAllDiaglogs}
        onClickHandler={confirmResendHandler}
        dialogTitle={
          fullName ? `Resend invitation to ${fullName}?` : `Resend invitation?`
        }
        dialogText="If you choose to continue an additional email will be sent to this individual who has already received one previously."
        declineBtnText="Cancel"
        acceptBtnText="Send"
        isLoading={isLoading}
      />
      <AlertDialog
        setOpenDialog={setOpenDeleteDialog}
        openDialog={openDeleteDialog}
        handleClose={handleCloseAllDiaglogs}
        onClickHandler={confirmDeleteHandler}
        dialogTitle={
          fullName
            ? `Delete ${fullName}?`
            : `Are you sure you want to delete this user?`
        }
        dialogText="This email recipient will be completely removed from the invitation list. You can always re-invite the individual at a later date."
        declineBtnText="Cancel"
        acceptBtnText="Delete"
        isLoading={isLoading}
      />
      <AlertDialog
        setOpenDialog={setOpenSuccessDialog}
        openDialog={openSuccessDialog}
        handleClose={handleCloseAllDiaglogs}
        // onClickHandler={onClickHandler}
        dialogTitle="Sent Successfully"
        dialogText="The email invitation has been successfully sent to the recipient."
        declineBtnText="Close"
        //   isLoading={isLoading}
        image="PAPER_AIRPLANE"
      >
        <p>The email invitation has been successfully sent to the recipient.</p>
      </AlertDialog>
      {/* {!hasErrors && snackOpen && (
        <Snackbar
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
        >
          <MuiAlert onClose={handleSnackClose} severity="success">
            Saved Successfully
          </MuiAlert>
        </Snackbar>
      )}
      {hasErrors && snackOpen && (
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <MuiAlert onClose={handleSnackClose} severity="error">
            Your changes were not saved, check the entered details and try
            again.
          </MuiAlert>
        </Snackbar>
      )} */}
    </>
  );
};

export default getMemberInvites(
  resendMemberInvite(deleteMemberInvite(InvitesTable))
);
