import { gql } from "@apollo/client";
import { omit } from "lodash/fp";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const PayPeriodBookingsFragment = gql`
  fragment PayPeriodBookingsFragment on payPeriod {
    id
    predicted
    dateRange
    totalBasePay
    totalBonusPay
    totalPay
    bookings {
      bookingId
      hours
      breakHours
      date
      role
      venue
      hasBonus
      totalShiftPay
      cancelled
    }
  }
`;

const FETCH_PAY_PERIOD_BOOKINGS = gql`
  ${PayPeriodBookingsFragment}
  query getPayPeriodBookings($memberId: ID!, $id: ID!) {
    account {
      id
      member(id: $memberId) {
        id
        payPeriod(id: $id) {
          ...PayPeriodBookingsFragment
        }
      }
    }
  }
`;

export default graphql(FETCH_PAY_PERIOD_BOOKINGS, {
  options(props) {
    return {
      fetchPolicy: "cache-and-network",
      variables: {
        memberId: props.member.id,
        id: props.payPeriodId
      }
    };
  },
  props: handleErrors(({ data: { account, loading, error } }) => ({
    payPeriodBookings: account?.member?.payPeriod?.bookings,
    payPeriodMeta: omit("bookings", account?.member?.payPeriod),
    isLoadingPayPeriod: loading,
    errorPayPeriod: error
  }))
});
