import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

const SEND_INVITE_EMAIL = gql`
  mutation sendMemberInviteEmail(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    sendMemberInviteEmail(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      email
    }
  }
`;

export default graphql(SEND_INVITE_EMAIL, {
  name: "sendMemberInviteEmail",
  props: ({ sendMemberInviteEmail }) => ({
    sendMemberInviteEmail: ({ email, firstName, lastName }) =>
      sendMemberInviteEmail({
        variables: {
          email,
          firstName,
          lastName
        },
        refetchQueries: ["getMemberInvites"]
      })
  })
});
