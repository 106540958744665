import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import update from "lodash/fp/update";

import getMemberVariables from "../utils/get-member-variables";
import { handleErrors } from "~/src/utils/errors";

const GET_MEMBERS = gql`
  query getMembers(
    $searchText: String
    $offset: Int!
    $limit: Int!
    $minRating: Float
    $maxRating: Float
    $roleNames: [String]
    $serviceAreaId: ID
    $stateIn: [MemberStateType]
    $workedWithPartnersIn: [ID]
    $bookingStateIn: [String]
    $bookingStateStartTime: Date
    $bookingStateEndTime: Date
    $bookingStateNotBetweenStartTime: Date
    $bookingStateNotBetweenEndTime: Date
  ) {
    account {
      id
      isSmsOptionAvailable
      members(
        searchText: $searchText
        offset: $offset
        limit: $limit
        minRating: $minRating
        maxRating: $maxRating
        roleNames: $roleNames
        serviceAreaId: $serviceAreaId
        stateIn: $stateIn
        workedWithPartnersIn: $workedWithPartnersIn
        bookingStateIn: $bookingStateIn
        bookingStateStartTime: $bookingStateStartTime
        bookingStateEndTime: $bookingStateEndTime
        bookingStateNotBetweenStartTime: $bookingStateNotBetweenStartTime
        bookingStateNotBetweenEndTime: $bookingStateNotBetweenEndTime
      ) {
        totalResults
        data {
          id
          firstName
          lastName
          nickname
          photo
        }
      }
    }
  }
`;

export default limit =>
  graphql(GET_MEMBERS, {
    props: handleErrors(({ data: { loading, account, fetchMore } }) => {
      return {
        areMembersLoading: loading,
        members: account?.members?.data,
        isSmsOptionAvailable: account?.isSmsOptionAvailable,
        totalResults: account?.members?.totalResults,
        fetchMore: offset => loadMore(offset, fetchMore)
      };
    }),
    options: ownProps => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        limit,
        offset: ownProps?.offset ?? 0,
        ...getMemberVariables(ownProps)
      }
    })
  });

export function loadMore(offset, fetchMore) {
  return fetchMore({
    notifyOnNetworkStatusChange: true,
    variables: { offset },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      update(
        "account.members.data",
        current => [...current, ...fetchMoreResult?.account?.members?.data],
        previousResult
      )
  });
}
