import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { iconNew } from "@teamrota/rota-design";
import sendMemberInviteEmail from "../graphql";

import styles from "../dialogs/slide-dialog/slide-dialog.styles";
import { Button, DialogActions } from "@material-ui/core";
import Loading from "~/src/components/loading";
import getMemberInvites from "../../invites/InvitesTable/graphql";

const schema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  email: yup
    .string()
    .email("Please provide a valid email address")
    .required("This field is required")
});
const { Warning } = iconNew;

const InviteForm = ({
  sendMemberInviteEmail,
  setOpenSlideDialog,
  handleClickOpenDialog,
  isLoading,
  getMemberInvites
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: true
  });

  const [membersInvitedEmails, setMembersInvitedEmails] = useState([]);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);

  useEffect(() => {
    const memberInvites = getMemberInvites?.memberInvites;
    setMembersInvitedEmails(memberInvites?.map(member => member.email));
  }, [getMemberInvites]);

  const onSubmit = data => {
    const { firstName, lastName, email } = data;

    if (membersInvitedEmails?.includes(email)) {
      setIsDuplicateEmail(true);
      return null;
    }
    try {
      sendMemberInviteEmail({
        firstName,
        lastName,
        email
      });
      setIsDuplicateEmail(false);
      reset();
      setOpenSlideDialog(false);
      handleClickOpenDialog();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    reset();
    setIsDuplicateEmail(false);
    setOpenSlideDialog(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={styles.field}>
        <label style={styles.smallMarginBottom}>First Name</label>
        <input
          name="firstName"
          style={styles.inputField}
          {...register("firstName")}
        />
        {errors.firstName?.message && (
          <span style={styles.validationErrorMessage}>
            <Warning />
            <span style={styles.marginLeft}>{errors.firstName?.message}</span>
          </span>
        )}
      </div>

      <div style={styles.field}>
        <label style={styles.smallMarginBottom}>Last Name</label>
        <input
          name="lastName"
          style={styles.inputField}
          {...register("lastName")}
        />
        {errors.lastName?.message && (
          <span style={styles.validationErrorMessage}>
            <Warning />
            <span style={styles.marginLeft}>{errors.lastName?.message}</span>
          </span>
        )}
      </div>
      <div style={styles.field}>
        <label style={styles.smallMarginBottom}>Email</label>
        <input name="email" style={styles?.inputField} {...register("email")} />
        {isDuplicateEmail && <p>This member has already been invited</p>}
        {errors.email?.message && (
          <span style={styles.validationErrorMessage}>
            <Warning />
            <span style={styles.marginLeft}>{errors.email?.message}</span>
          </span>
        )}
      </div>

      <DialogActions style={styles.dialogActions}>
        <Button
          type="submit"
          autoFocus
          style={isValid ? styles.button : styles.disabledButton}
        >
          Send
          {isLoading && <Loading color="black" />}
        </Button>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          style={styles.declineBtn}
        >
          Cancel
        </Button>
      </DialogActions>
    </form>
  );
};

export default sendMemberInviteEmail(getMemberInvites(InviteForm));
