import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Section = styled.span`
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT};
`;

export const Scrollable = styled.div`
  height: calc(100% - 50px);
  margin: -${STYLE_CONSTS.UNIT_1};
  padding: ${STYLE_CONSTS.UNIT_1};
  overflow-y: auto;
`;

export const TotalWrapper = styled.div`
  background: ${COLORS.PALE_GREY};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  padding: ${STYLE_CONSTS.UNIT_1};
  color: ${COLORS.COOL_GREY};
  font-weight: bold;
  text-align: center;
  font-size: ${STYLE_CONSTS.FONT};
`;

export const Total = styled.span`
  color: ${COLORS.DARK_GREEN};
`;
