import React from "react";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import capitalize from "lodash/fp/capitalize";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import FiltersDateRange from "~/src/components/form-components/filters-date-range";
import ActionDropdown from "~/src/components/action-dropdown";

import FilterCheckbox from "./checkbox";
import FilterSelect from "./select";
import FilterRatings from "./ratings";

import {
  Container,
  StyledSearchInput,
  Panel,
  Title,
  Tab,
  FilterOptions
} from "../../notifications.styles";
import getFilterSuggestions from "../../graphql/get-filter-suggestions";
import { MEMBER_STATES, BOOKING_STATES_LABELS } from "~/src/consts";

import {
  onSearchMember,
  onSearchShift,
  onFilterOptionUpdate,
  onUpdatePartnerQuery,
  onDateUpdate
} from "../../reducer";

const MEMBER_STATE_OPTIONS = Object.keys(MEMBER_STATES).map(state => ({
  name: capitalize(state.toLowerCase().replace("_", " ")),
  id: state
}));

const BOOKING_STATE_OPTIONS = Object.entries(BOOKING_STATES_LABELS).map(
  ([id, name]) => ({
    id,
    name
  })
);

const bindActions = dispatch =>
  bindActionCreators(
    {
      onSearchMember,
      onSearchShift,
      onFilterOptionUpdate,
      onUpdatePartnerQuery,
      onDateUpdate
    },
    dispatch
  );
const Filters = props => (
  <div>
    <Title>Find Recipients</Title>
    <Panel>
      <Container
        justifyContent="space-around"
        margin={`0 0 ${STYLE_CONSTS.UNIT_4} 0`}
      >
        <Tab
          onClick={() => props.onUpdateActiveTab("members")}
          isDisabled={props.activeTab !== "members"}
        >
          by Member
        </Tab>
        <Tab
          onClick={() => props.onUpdateActiveTab("shifts")}
          isDisabled={props.activeTab !== "shifts"}
        >
          by Shift
        </Tab>
      </Container>

      {props.activeTab === "members" && (
        <StyledSearchInput
          isInline
          isSmall
          onChange={props.onSearchMember}
          placeholder="Search staff member by name, email, phone or ID"
        />
      )}

      {props.activeTab === "shifts" && (
        <StyledSearchInput
          isInline
          isSmall
          onChange={props.onSearchShift}
          placeholder="Search shifts by name or ID"
        />
      )}
    </Panel>

    <Title>Filter by</Title>
    <Panel>
      <FilterCheckbox
        label="Roles"
        name="roles"
        openComponent={checkProps => (
          <FilterSelect suggestions={props.roles} {...checkProps} />
        )}
      />

      <FilterCheckbox
        label="Service Area"
        name="service-area"
        openComponent={checkProps => (
          <FilterOptions>
            <ActionDropdown
              defaultValue="Any"
              isFullWidth
              isBlock
              isOutline
              isSquare
              value={props.selectedFilterOptions[checkProps.name]}
              options={props.serviceAreas}
              onChange={value =>
                props.onFilterOptionUpdate({ name: checkProps.name, value })
              }
            />
          </FilterOptions>
        )}
      />

      {props.activeTab === "members" && (
        <div>
          <FilterCheckbox
            label="Worked with (partner)"
            name="worked-with"
            openComponent={checkProps => (
              <FilterSelect
                suggestions={props.partners}
                handleInputChange={props.onUpdatePartnerQuery}
                {...checkProps}
              />
            )}
          />

          <FilterCheckbox
            label="Rating"
            name="rating"
            openComponent={() => <FilterRatings />}
          />
          <FilterCheckbox
            label="Status"
            name="status"
            openComponent={checkProps => (
              <FilterSelect
                suggestions={MEMBER_STATE_OPTIONS}
                {...checkProps}
              />
            )}
          />
          <FilterCheckbox
            label="Booking states"
            name="booking-states"
            openComponent={checkProps => (
              <div>
                <FilterSelect
                  suggestions={BOOKING_STATE_OPTIONS}
                  {...checkProps}
                />
                <FilterCheckbox
                  label="States Between"
                  name="booking-states-between"
                  openComponent={checkProps => (
                    <FilterOptions>
                      <FiltersDateRange
                        startTime={get(
                          [checkProps.name, "startTime"],
                          props.selectedFilterOptions
                        )}
                        endTime={get(
                          [checkProps.name, "endTime"],
                          props.selectedFilterOptions
                        )}
                        onChangeStartTime={startTime =>
                          props.onDateUpdate({
                            ...checkProps,
                            value: { startTime }
                          })
                        }
                        onChangeEndTime={endTime =>
                          props.onDateUpdate({
                            ...checkProps,
                            value: { endTime }
                          })
                        }
                      />
                    </FilterOptions>
                  )}
                />

                <FilterCheckbox
                  label="States Not Between"
                  name="booking-states-not-between"
                  openComponent={checkProps => (
                    <FilterOptions>
                      <FiltersDateRange
                        startTime={get(
                          [checkProps.name, "startTime"],
                          props.selectedFilterOptions
                        )}
                        endTime={get(
                          [checkProps.name, "endTime"],
                          props.selectedFilterOptions
                        )}
                        onChangeStartTime={startTime =>
                          props.onDateUpdate({
                            ...checkProps,
                            value: { startTime }
                          })
                        }
                        onChangeEndTime={endTime =>
                          props.onDateUpdate({
                            ...checkProps,
                            value: { endTime }
                          })
                        }
                      />
                    </FilterOptions>
                  )}
                />
              </div>
            )}
          />
        </div>
      )}

      {props.activeTab === "shifts" && (
        <div>
          <FilterCheckbox
            label="Shift date"
            name="shift-date"
            openComponent={checkProps => (
              <FilterOptions>
                <FiltersDateRange
                  startTime={get(
                    [checkProps.name, "startTime"],
                    props.selectedFilterOptions
                  )}
                  endTime={get(
                    [checkProps.name, "endTime"],
                    props.selectedFilterOptions
                  )}
                  onChangeStartTime={startTime => {
                    props.onDateUpdate({
                      ...checkProps,
                      value: { startTime }
                    });
                  }}
                  onChangeEndTime={endTime => {
                    props.onDateUpdate({
                      ...checkProps,
                      value: { endTime }
                    });
                  }}
                />
              </FilterOptions>
            )}
          />
          <FilterCheckbox label="Finalised" name="shift-finalised" />
          <FilterCheckbox label="Cancelled" name="shift-cancelled" />
        </div>
      )}
    </Panel>
  </div>
);

export default flow(
  getFilterSuggestions,
  connect(
    s => ({
      selectedFilterOptions: s.notifications.selectedFilterOptions,
      partnerQuery: s.notifications.partnerQuery
    }),
    bindActions
  )
)(Filters);
