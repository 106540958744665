import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import LogFragment from "~/src/graphql/fragments/log/log.fragment";
import AccountRoleFragment from "~/src/graphql/fragments/account-role";

const GET_TARGET_ACCOUNT = addAuthVars(gql`
  ${LogFragment}
  ${AccountRoleFragment}
  query getTargetAccount($accountId: ID!) {
    account {
      id
      roles {
        ...AccountRoleFragment
      }

      connectionPolicy(targetAccountId: $accountId) {
        id
        minimumShiftLength
        minimumShiftPercentage
        cancellationPeriod
      }

      targetAccount(accountId: $accountId) {
        id
        accountName
        logo
        createdAt
        accountName
        contactEmail
        contactWeb
        contactPhone
        isAutoAcceptEnabled
        isRequestAllEnabled
        isRebookingEnabled
        isSleepTimeEnabled
        notes
        ownerId
        subvenueSelection
        logs(targetAccountId: $accountId) {
          ...LogFragment
        }

        documents {
          id
          url
          format
          type
          label
        }

        s3Documents {
          id
          format
          type
          fileName
          url
        }

        matcherConfig {
          id
          hoursBeforeShift
          algoSkew
          favouriteFactor
          isPartnerManagedEnabled
          globalRatingFactor
          roleFactor
          relationshipFactor
          autoAcceptThreshold
        }

        users {
          id
          firstName
          lastName
          email
          phoneNumber
        }

        tags {
          id
          name
        }

        roleRates(accountType: requester) {
          id
          roleId
          startDate
          endDate

          ... on roleRate {
            payRate
            chargeRate
          }

          tags {
            id
            name
          }
          isDayRate
        }

        uncalculatedRoleRates(accountType: requester) {
          id
          name
          roleId
          isDayRate
          payRate
          chargeRate
          applicable
        }
      }
    }
  }
`);

export const useTargetAccount = ({ accountId }) => {
  const auth = useAuth();

  const { data, loading, error, refetch } = useQuery(GET_TARGET_ACCOUNT, {
    skip: !accountId,
    variables: auth.addVals(GET_TARGET_ACCOUNT, { accountId }),
    fetchPolicy: "network-only",
    onError: error => {
      throw error;
    }
  });

  const targetAccount = data?.account?.targetAccount;
  const allRoles = data?.account?.roles || [];
  const calculatedRoles = allRoles.filter(role => !role.isUncalculatedRate);
  const uncalculatedRoles = allRoles.filter(role => role.isUncalculatedRate);

  return {
    targetAccount,
    calculatedRoles,
    uncalculatedRoles,
    connectionPolicy: data?.account?.connectionPolicy,
    roleRates: targetAccount?.roleRates || [],
    uncalculatedRoleRates: targetAccount?.uncalculatedRoleRates || [],
    loading,
    error,
    refetch
  };
};
