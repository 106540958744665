import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const CREATE_OR_UPDATE_EVOLVE_CLIENT_INFORMATION = gql`
  mutation createOrUpdateEvolveClientInformation(
    $accountId: ID!
    $retroPercentage: Float
    $accountingCycles: String
    $isParent: Boolean
    $parentId: ID
    $location: String
    $department: String
  ) {
    createOrUpdateEvolveClientInformation(
      accountId: $accountId
      retroPercentage: $retroPercentage
      accountingCycles: $accountingCycles
      isParent: $isParent
      parentId: $parentId
      location: $location
      department: $department
    ) {
      accountId
    }
  }
`;

export default graphql(CREATE_OR_UPDATE_EVOLVE_CLIENT_INFORMATION, {
  name: "createOrUpdateEvolveClientInformation",
  props: ({ createOrUpdateEvolveClientInformation }) => ({
    createOrUpdateEvolveClientInformation: ({
      accountId,
      retroPercentage,
      accountingCycles,
      isParent,
      parentId,
      location,
      department
    }) =>
      createOrUpdateEvolveClientInformation({
        variables: {
          accountId,
          retroPercentage: retroPercentage,
          accountingCycles: accountingCycles,
          isParent: isParent,
          parentId: parentId || null,
          location: location,
          department: department
        },
        refetchQueries: ["getEvolveClientInformation"]
      })
  })
});
