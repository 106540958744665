import React from "react";
import Money from "~/src/utils/money";
import Icon from "~/src/components/icon";
import Loading from "~/src/components/loading";
import PayPeriods from "./components/pay-periods";
import getPayPeriods from "./graphql/get-pay-periods";
import { Scrollable, Total, TotalWrapper } from "./member-pay-history.styles";

// NOTE: Although not required in the MemberPayHistory component itself
// the enclosing getPayPeriods GraphQL call requires a
// `memberId` prop to be passed in, for use in the GraphQL variables
// Otherwise pages with this component will trigger an
// OSOD (Orange Screen of Death) error page
const MemberPayHistory = ({ fetchMorePeriods, isLoading, member }) => {
  if (isLoading) {
    return (
      <Loading
        alignCenter="true"
        implementSafariLoadHack={navigator.userAgent.indexOf("Safari") > 1}
        color={Icon.colors.BLACK}
      />
    );
  }

  return (
    <Scrollable>
      <TotalWrapper>
        Total Overall Earnings:
        <Total>
          {" "}
          {Money({ amount: member?.totalEarnings || 0 }).toFormat()}
        </Total>
      </TotalWrapper>
      <PayPeriods
        isLoading={isLoading}
        fetchMore={fetchMorePeriods}
        periods={member?.payPeriods?.data}
        member={member}
      />
    </Scrollable>
  );
};

export default getPayPeriods(MemberPayHistory);
