import React from "react";
import Modal from "~/src/components/modal";

import { RotaButton } from "@teamrota/rota-design";

import { ZENDESK_SUPPORT_LINK } from "~/src/consts";

import {
  Container,
  Header,
  ButtonContainer,
  MessageContainer,
  backgroundColor,
  Image,
  ContactLink,
  Text
} from "./modal-error.styles";

export default ({ title, children, showHelp = true, buttonText, onClose }) => (
  <Modal isOpen isOverlay onClose={onClose} background={backgroundColor}>
    <Container>
      <Header>{title}</Header>
      <MessageContainer>
        <Image src="/images/error.png" />
        <Text>{children}</Text>
        {showHelp && (
          <Text>
            If this problem persists, please{" "}
            <ContactLink href={ZENDESK_SUPPORT_LINK} target="_blank">
              contact us
            </ContactLink>
          </Text>
        )}
      </MessageContainer>
      <ButtonContainer>
        <RotaButton variant="outlined" onClick={onClose}>
          {buttonText}
        </RotaButton>
      </ButtonContainer>
    </Container>
  </Modal>
);
