import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import { Card, Section } from "../pay-periods/pay-periods.styles";

export const LittleBoxes = styled(Card)`
  text-align: center;
  background-color: ${({ theme }) => theme.white};
  box-shadow: none;
  margin: auto;
  padding: ${STYLE_CONSTS.UNIT_1};
  width: 22%;
`;

export const BoxRow = styled.div`
  align-items: center;
  background-color: ${COLORS.CLOUDY_BLUE};
  display: flex;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  flex-direction: row;
  justify-content: space-around;
  margin: -${STYLE_CONSTS.UNIT_1} -${STYLE_CONSTS.UNIT_1} ${STYLE_CONSTS.UNIT_1};
  padding: ${STYLE_CONSTS.UNIT_1};
`;

export const BoxTitle = styled.h4`
  align-text: center;
  color: ${COLORS.COOL_GREY};
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const BoxAmount = styled(Section)`
  align-text: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  margin: 0 auto;
`;

export const Text = styled.p`
  margin: ${STYLE_CONSTS.UNIT_1};
`;
