import { MEMBER_STATES } from "~/src/consts";

export const COLORS = {
  MAIN_ORANGE: "#ff9300",
  BLAZE_ORANGE: "#FF5C00",

  BLACK: "#1c1b1b",
  WHITE: "#ffffff",
  NEAR_WHITE: "#F4F4F4",
  GRID_GREY: "#e0e5ea",
  COOL_GREY: "#939ba7",
  MUI_LIGHT: "#f5f5f5",
  CLOUDY_BLUE: "#c0ccda",
  PINK: "#ff327b",
  SLATE: "#3c4858",
  PURPLE: "#7e5bef",
  RED: "#ff4949",
  BACKGROUND_RED: "#ffe1e1",
  GREEN: "#13ce66",
  LIGHT_GREEN: "#E0F9EA",
  DARK_GREEN: "#008000",
  YELLOW: "#ffc82c",
  CORN_YELLOW: "#DAB700",
  MEDIUM_YELLOW: "#FFDB21",
  LIGHT_YELLOW: "#FFF8B7",
  PALE_GREY: "#e5e9f2",
  CANDY_PINK: "#ff49db",
  DARK_GREY: "#1C1B1B",
  WARM_GREY: "#8c8c8c",
  WILD_SAND_GREY: "#F4F4F4",
  PALE_GREY_TRANSPARENT_30: "rgba(229, 233, 242, 0.3)",
  PALE_GREY_TRANSPARENT_50: "rgba(229, 233, 242, 0.5)",
  PALE_GREY_TRANSPARENT_98: "rgb(229, 233, 242)",
  PALE_YELLOW: "#ff0000",

  ROMANTIC: "#FFD0AE",

  // no equivalent color
  GREY_LIGHTER: "#F4F2FA",
  GREY_DARKER: "#E0DFE3",
  GREY_MEDIUM: "#D8DCE3",
  GREY_DARK: "#444444",
  BTN_GREY: "#EBEBEB",

  // not used
  AZURE: "#00a3f5",
  SILVER: "#bbbdbf"
};

export const EVOLVE_THEME = {
  PURPLE: "#990465"
};

export const MEMBER_STATES_COLORS = {
  [MEMBER_STATES.ACTIVE]: COLORS.GREEN,
  [MEMBER_STATES.REMOVED]: COLORS.RED,
  [MEMBER_STATES.LOCKED]: COLORS.SLATE,
  [MEMBER_STATES.GOOD_LEAVER]: COLORS.PURPLE,
  [MEMBER_STATES.PENDING]: COLORS.MAIN_ORANGE,
  [MEMBER_STATES.ASLEEP]: COLORS.YELLOW,
  [MEMBER_STATES.REJECTED]: COLORS.RED
};

// Auto Generate the UNITs up to 50
function generateUnits() {
  return Array(50)
    .fill(0)
    .reduce(
      (obj, item, i) => ({ ...obj, [`UNIT_${i + 1}`]: `${(i + 1) * 6}px` }),
      {}
    );
}

export const STYLE_CONSTS = {
  PRIMARY_GRADIENT: "linear-gradient(to top, #ee7543, #f3a057 51%, #fad172)",
  GREY_GRADIENT: "linear-gradient(to top, #939ca6, #c0ccd8 51%, #d4dfeb)",

  // New media query break points
  MOBILE_DEVICE_MAX: "480px",
  TABLET_MIN: "481px",
  TABLET_MAX: "768px",
  LAPTOP_MIN: "769px",
  LAPTOP_MAX: "1024px",
  MONITOR_MIN: "1025px",
  MONITOR_MAX: "1200px",

  // Break points
  BREAK_POINT_SMALL: "40em",
  BREAK_POINT_MEDIUM: "52em",
  BREAK_POINT_LARGE: "64em",
  BREAK_POINT_XLARGE: "76em",
  BREAK_POINT_XXLARGE: "94em",

  // Font sizes
  FONT_XXSMALL: "8px",
  FONT_XSMALL: "10px",
  FONT_SMALL: "12px",
  FONT: "14px",
  FONT_REGULAR: "15px",
  FONT_MEDIUM: "17px",
  FONT_LARGE: "20px",
  FONT_XLARGE: "24px",
  FONT_XXLARGE: "36px",

  // Font Weights
  FONT_WEIGHT: "300",
  FONT_WEIGHT_MEDIUM: "500",
  FONT_WEIGHT_BOLD: "700",

  // Spacing/Unit sizing
  UNIT_HALF: "3px",
  UNIT_1: "6px",
  UNIT_2: "12px",
  UNIT_3: "18px",
  UNIT_4: "24px",
  UNIT_5: "30px",
  UNIT_6: "36px",
  UNIT_8: "48px",
  UNIT_9: "54px",
  UNIT_10: "60px",
  ...generateUnits(),

  // User avatar sizing
  THUMBNAIL_SIZE: "60px",

  // Border radius
  BORDER_RADIUS_S: "3px",
  BORDER_RADIUS: "6px",
  BORDER_RADIUS_L: "8px",
  BORDER_RADIUS_XL: "16px",

  // Box shadow
  FLOATING: "0 1px 3px 0px rgba(0, 0, 0, 0.05)",
  FLOATING_MEDIUM: "0 1px 10px 0px rgba(0, 0, 0, 0.05)",
  FLOATING_MID: "0 3px 6px 3px rgba(0, 0, 0, 0.1)",
  FLOATING_LARGE: "0 2px 20px 0 rgba(0, 0, 0, 0.2)",

  // Heights & width of buttons are hardcoded
  BTN_HEIGHT_L: "43px",
  BTN_HEIGHT_M: "36px",
  BTN_HEIGHT_S: "28px",
  BTN_HEIGHT_XS: "20px",
  BTN_HEIGHT_XXS: "14px",

  BTN_MIN_WIDTH_L: "113px",
  BTN_MIN_WIDTH_M: "93px",
  BTN_MIN_WIDTH_S: "86px",

  // Icons
  ICON_XXSMALL: 8,
  ICON_XSMALL: 10,
  ICON_SMALL: 18,
  ICON_MEDIUM: 24,
  ICON_LARGE: 38,
  ICON_XLARGE: 50,
  ICON_XXLARGE: 64,
  ICON_XXXLARGE: 88,

  // ...Icons extra sizing options
  ICON_SMALLMID: 12,

  // Menu
  MENU_SIZE: "75px", // NOTE: 'size' because its for height and width.

  STANDARD_TRANSITON: "all .5s ease",

  Z_INDEX_NAV_BAR: 100,
  Z_INDEX_MODAL: 1000,
  Z_INDEX_CALENDAR_POPUP: 80,
  Z_INDEX_DATE_PICKER: 85,
  Z_INDEX_SELECT: 85,

  SMALL_DIVIDER_HEIGHT: "2px",
  SMALL_DIVIDER_BORDER: `solid 0.6px ${COLORS.CLOUDY_BLUE}`,
  SMALL_DIVIDER_BORDER_LIGHT: `solid 0.6px ${COLORS.GREY_LIGHTER}`,
  MED_DIVIDER_BORDER_MED_LIGHT: `solid thin ${COLORS.GREY_DARKER}`,

  BACKGROUND_GRADIENT: "linear-gradient(to top, #ee7543, #f3a057 51%, #fad172)"
};

export const flexCentered = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
