import { gql } from "@apollo/client";

export const GET_ROLE_RATE = gql`
  query getRoleRate($roleRateId: ID!) {
    account {
      id
      roleRateById(roleRateId: $roleRateId) {
        id
        payRate
        chargeRate
        isOverstayRate
        overstayPayRate
        overstayChargeRate
        overstayHoursLimit
        isOvertimeRate
        overtimePayRate
        overtimeChargeRate
        overtimeHoursLimit
        isUncalculatedRate
      }
    }
  }
`;
