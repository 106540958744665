import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Container, Row, Col } from "react-grid-system";
import DatePicker from "react-datepicker";
import SignaturePad from "react-signature-canvas";
import { useHistory, useLocation } from "react-router";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import * as yup from "yup";

import { evolveLinks, nationalities } from "@teamrota/rota-common";

import { GENDER_OPTIONS, REFERRAL_METHODS } from "~/src/consts";
import client from "~/src/services/graphql";
import RequiredAsterisk from "./RequiredAsterisk";
import createEvolveMember, {
  CHECK_MEMBER_EMAIL,
  GET_INVITED_MEMBER_DETAILS
} from "./graphql";

import {
  ContainerDiv,
  Div_Field,
  Form,
  Input,
  Label,
  P_header_mb,
  Select,
  Signature_Container,
  Span_Error,
  Span_MB20,
  TitleText,
  TitleDiv,
  Span_Radio_Btn,
  ButtonContainerDiv,
  SubmitButton,
  CancelButton,
  SectionHeader,
  PBold,
  Link
} from "./styles";

const {
  keyInformationDocumentUrl,
  termsOfEngagementUrl,
  medicalStatementUrl,
  uniformStatementUrl,
  GDPRConsentFormUrl
} = evolveLinks;

const REQUIRED_MESSAGE = "This field is required";
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid email address")
    .required(REQUIRED_MESSAGE),
  title: yup.string().required(REQUIRED_MESSAGE),
  firstName: yup.string().required(REQUIRED_MESSAGE),
  lastName: yup.string().required(REQUIRED_MESSAGE),
  houseNumber: yup.string().required(REQUIRED_MESSAGE),
  town: yup.string().required(REQUIRED_MESSAGE),
  postcode: yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: yup.string().required(REQUIRED_MESSAGE),
  gender: yup.string().required(REQUIRED_MESSAGE),
  birthDate: yup.string().required(REQUIRED_MESSAGE),
  nationality: yup.string().required(REQUIRED_MESSAGE),
  emergencyContactTitle: yup.string().required(REQUIRED_MESSAGE),
  emergencyContactName: yup.string().required(REQUIRED_MESSAGE),
  emergencyContactRelationship: yup.string().required(REQUIRED_MESSAGE),
  emergencyContactNumber: yup.string().required(REQUIRED_MESSAGE),
  emergencyContactEmail: yup
    .string()
    .email("Please provide a valid email address"),
  bankName: yup.string(),
  accountName: yup.string(),
  accountNumber: yup
    .string()
    .test(
      "len",
      "Account number incorrect - Must be exactly 8 numbers",
      val => val === "" || /^[0-9]{8}$/.test(val)
    ),
  sortCode: yup
    .string()
    .test(
      "len",
      "Sort code incorrect - Must be exactly 6 numbers",
      val => val === "" || /^\d{6}$/.test(val)
    ),
  isKeyInformationDocumentAccepted: yup
    .bool()
    .oneOf([true], "You must accept the Key Information Document"),
  isTermsOfEngagementAccepted: yup
    .bool()
    .oneOf([true], "You must accept the Terms Of Engagement"),
  isMedicalStatementAccepted: yup
    .bool()
    .oneOf([true], "You must accept the Medical Statement"),
  isUniformStatementAccepted: yup
    .bool()
    .oneOf([true], "You must accept the Uniform Statement"),
  isGDPRConsentFormAgreed: yup
    .bool()
    .oneOf([true], "You must agree to the GDPR Consent Form"),
  agreeWorkingTimesOptions: yup.string(),
  employeeStatementOptions: yup.string(),
  isStudentLoan: yup.bool(),
  isPostgraduateLoan: yup.bool(),
  signatureFullName: yup.string().required(REQUIRED_MESSAGE),
  signatureDate: yup.string().required(REQUIRED_MESSAGE),
  isCriminalConviction: yup.bool(),
  isFoodHygieneCertificate: yup.bool()
});

const EvolveForm = ({ createEvolveMember }) => {
  const history = useHistory();
  const location = useLocation();

  const [imageURL, setImageURL] = useState(null);
  const [signatureError, setSignatureError] = useState(false);
  const [isEmailTaken, setIsEmailTaken] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      agreeWorkingTimesOptions: "Option 1",
      employeeStatementOptions: "Option A",
      isCriminalConviction: "false",
      isFoodHygieneCertificate: "false"
    }
  });

  const email = useWatch({
    control,
    name: "email",
    defaultValue: ""
  });

  const { refetch } = useQuery(CHECK_MEMBER_EMAIL, {
    variables: {
      email: ""
    }
  });

  const fetchAndSetIncomingMemberDetails = async () => {
    const invitedUserHashedId = location?.search?.split("=")?.[1];
    if (invitedUserHashedId) {
      try {
        const { data } = await client.query({
          query: GET_INVITED_MEMBER_DETAILS,
          variables: { hashedId: invitedUserHashedId },
          fetchPolicy: "network-only"
        });

        if (
          data?.invitedMemberDetails &&
          !_.isEmpty(data?.invitedMemberDetails)
        ) {
          setValue("email", data?.invitedMemberDetails?.email);
          setValue("firstName", data?.invitedMemberDetails?.firstName);
          setValue("lastName", data?.invitedMemberDetails?.lastName);
        }
      } catch (e) {
        console.log("e :>> ", e);
      }
    }
  };

  useEffect(() => {
    const checkNewEmail = async () => {
      const res = await refetch({
        email
      });
      const responseIsEmailTaken = res?.data?.checkMemberEmail?.isEmailTaken;

      if (responseIsEmailTaken) {
        setIsEmailTaken(true);
      } else {
        setIsEmailTaken(false);
      }
    };

    checkNewEmail();
  }, [email]);

  useEffect(() => {
    fetchAndSetIncomingMemberDetails();
  }, []);

  const sigCanvas = useRef({});

  const onSubmit = async data => {
    setSignatureError(false);
    if (!imageURL) setSignatureError(true);
    else {
      await createEvolveMember(null, {
        ...data,
        signatureImage: imageURL,
        isEvolveOnboarding: true
      });
      reset();
      history.push("/evolve/complete");
    }
  };

  const onCancel = () => {
    reset();
    setSignatureError(false);
    setImageURL(null);
    sigCanvas.current.clear();
  };

  const onError = (errors, e) => console.log(errors, e);

  const clear = e => {
    e.preventDefault();
    setSignatureError(false);
    setImageURL(null);
    sigCanvas.current.clear();
  };

  const save = e => {
    e.preventDefault();
    setSignatureError(false);
    if (sigCanvas.current.isEmpty()) setSignatureError(true);
    else {
      setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  };

  return (
    <ContainerDiv>
      <img src="https://doc.rota.com/evolve/evolve.png" />
      <Container>
        <TitleDiv>
          <TitleText>Application Form</TitleText>
        </TitleDiv>
        <SectionHeader>
          Key Information Document
          <RequiredAsterisk />
        </SectionHeader>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <p>
              <Input
                name="isKeyInformationDocumentAccepted"
                type="checkbox"
                {...register("isKeyInformationDocumentAccepted")}
              />{" "}
              I have read and accept the{" "}
              <Link href={keyInformationDocumentUrl} target="_blank">
                Key Information Document
              </Link>
            </p>
            {errors.isKeyInformationDocumentAccepted?.message && (
              <Span_Error>
                {errors.isKeyInformationDocumentAccepted?.message}
              </Span_Error>
            )}
          </Col>
        </Row>
        <Span_MB20 />
        <Span_MB20>
          <P_header_mb>Details</P_header_mb>
        </Span_MB20>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  E-Mail Address
                  <RequiredAsterisk />
                </Label>
                <Input name="email" {...register("email")} />
                {isEmailTaken && (
                  <Span_Error>
                    This email address is already reserved. You will not be able
                    to submit the form with this email. If you believe this is
                    an error, contact Evolve.
                  </Span_Error>
                )}
                {errors.email?.message && (
                  <Span_Error>{errors.email?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Title
                  <RequiredAsterisk />
                </Label>
                <Select name="title" {...register("title")}>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </Select>
                {errors.title?.message && (
                  <Span_Error>{errors.title?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  First name (as appears on passport)
                  <RequiredAsterisk />
                </Label>
                <Input name="firstName" {...register("firstName")} />
                {errors.firstName?.message && (
                  <Span_Error>{errors.firstName?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Middle name</Label>
                <Input name="middleName" {...register("middleName")} />
                {errors.middleName?.message && (
                  <Span_Error>{errors.middleName?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Surname (as appears on passport)
                  <RequiredAsterisk />
                </Label>
                <Input name="lastName" {...register("lastName")} />
                {errors.lastName?.message && (
                  <Span_Error>{errors.lastName?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Address
                  <RequiredAsterisk />
                </Label>
                <Input name="houseNumber" {...register("houseNumber")} />
                {errors.houseNumber?.message && (
                  <Span_Error>{errors.houseNumber?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Address 2</Label>
                <Input name="streetName" {...register("streetName")} />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  City
                  <RequiredAsterisk />
                </Label>
                <Input name="town" {...register("town")} />
                {errors.town?.message && (
                  <Span_Error>{errors.town?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>County</Label>
                <Input name="county" {...register("county")} />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Post Code
                  <RequiredAsterisk />
                </Label>
                <Input name="postcode" {...register("postcode")} />
                {errors.postcode?.message && (
                  <Span_Error>{errors.postcode?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Phone Number</Label>
                <Input
                  name="phoneNumber"
                  type="tel"
                  {...register("phoneNumber")}
                />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Mobile Number
                  <RequiredAsterisk />
                </Label>
                <Input
                  name="mobileNumber"
                  type="tel"
                  {...register("mobileNumber")}
                />
                {errors.mobileNumber && (
                  <Span_Error>This field is required</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Telephone (International)</Label>
                <Input
                  name="phoneInternational"
                  type="tel"
                  {...register("phoneInternational")}
                />
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Gender <RequiredAsterisk />
                </Label>
                <Select name="gender" {...register("gender")}>
                  {GENDER_OPTIONS?.filter(o => o !== "Other")?.map(
                    (option, idx) => (
                      <option key={option + idx} value={option}>
                        {option}
                      </option>
                    )
                  )}
                </Select>
                {errors.gender?.message && (
                  <Span_Error>{errors.gender?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>NI Number</Label>
                <Input name="NINumber" {...register("NINumber")} />
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  DOB (DD/MM/YYYY)
                  <RequiredAsterisk />
                </Label>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
                {errors.birthDate?.message && (
                  <Span_Error>{errors.birthDate?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Nationality
                  <RequiredAsterisk />
                </Label>
                <Select name="nationality" {...register("nationality")}>
                  {nationalities.map((option, idx) => (
                    <option key={option + idx} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {errors.nationality?.message && (
                  <Span_Error>{errors.nationality?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <P_header_mb>Employment Details</P_header_mb>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Do you have any unspent criminal convictions?</Label>
                <Span_Radio_Btn>
                  <Input
                    type="radio"
                    id="yes"
                    name="isCriminalConviction"
                    value={true}
                    {...register("isCriminalConviction")}
                  />
                  <Label>Yes</Label>
                  <Input
                    type="radio"
                    id="no"
                    name="isCriminalConviction"
                    value={false}
                    {...register("isCriminalConviction")}
                  />
                  <Label>No</Label>
                </Span_Radio_Btn>
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Current Basic Food Hygience certificate?</Label>
                <Span_Radio_Btn>
                  <Input
                    type="radio"
                    id="yes"
                    name="isFoodHygieneCertificate"
                    value={true}
                    {...register("isFoodHygieneCertificate")}
                  />
                  <Label>Yes</Label>
                  <Input
                    type="radio"
                    id="no"
                    name="isFoodHygieneCertificate"
                    value={false}
                    {...register("isFoodHygieneCertificate")}
                  />
                  <Label>No</Label>
                </Span_Radio_Btn>
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Method of transport to work</Label>
                <Input
                  name="methodOfTransport"
                  {...register("methodOfTransport")}
                />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>How did you hear about us?</Label>
                <Select name="referralMethod" {...register("referralMethod")}>
                  {REFERRAL_METHODS?.map(({ name }, idx) => (
                    <option key={name + idx} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
                {errors.referralMethod && (
                  <Span_Error>This field is required</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <P_header_mb>Emergency Contact Details</P_header_mb>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Title
                  <RequiredAsterisk />
                </Label>
                <Select
                  name="emergencyContactTitle"
                  {...register("emergencyContactTitle")}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                  <option value="Other">Other</option>
                </Select>
                {errors.emergencyContactTitle?.message && (
                  <Span_Error>
                    {errors.emergencyContactTitle?.message}
                  </Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}></Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Name
                  <RequiredAsterisk />
                </Label>
                <Input
                  name="emergencyContactName"
                  {...register("emergencyContactName")}
                />
                {errors.emergencyContactName?.message && (
                  <Span_Error>
                    {errors.emergencyContactName?.message}
                  </Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Relationship
                  <RequiredAsterisk />
                </Label>
                <Input
                  name="emergencyContactRelationship"
                  {...register("emergencyContactRelationship")}
                />
                {errors.emergencyContactRelationship?.message && (
                  <Span_Error>
                    {errors.emergencyContactRelationship?.message}
                  </Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Telephone
                  <RequiredAsterisk />
                </Label>
                <Input
                  name="emergencyContactNumber"
                  {...register("emergencyContactNumber")}
                />
                {errors.emergencyContactNumber?.message && (
                  <Span_Error>
                    {errors.emergencyContactNumber?.message}
                  </Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Email</Label>
                <Input
                  name="emergencyContactEmail"
                  {...register("emergencyContactEmail")}
                />
                {errors.emergencyContactEmail?.message && (
                  <Span_Error>
                    {errors.emergencyContactEmail?.message}
                  </Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <P_header_mb>Bank Account Details</P_header_mb>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Name of bank/building society</Label>
                <Input name="bankName" {...register("bankName")} />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Name in which the account is held</Label>
                <Input name="accountName" {...register("accountName")} />
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Branch</Label>
                <Input name="bankBranch" {...register("bankBranch")} />
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Account Number</Label>
                <Input name="accountNumber" {...register("accountNumber")} />
                {errors.accountNumber?.message && (
                  <Span_Error>{errors.accountNumber?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Sort Code</Label>
                <Input name="sortCode" {...register("sortCode")} />
                {errors.sortCode?.message && (
                  <Span_Error>{errors.sortCode?.message}</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>Roll Number (If needed)</Label>
                <Input name="rollNumber" {...register("rollNumber")} />
              </Div_Field>
            </Col>
          </Row>
          <SectionHeader>
            Terms of Engagement
            <RequiredAsterisk />
          </SectionHeader>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <p>
                <Input
                  name="isTermsOfEngagementAccepted"
                  type="checkbox"
                  {...register("isTermsOfEngagementAccepted")}
                />{" "}
                I have read and accept the{" "}
                <Link href={termsOfEngagementUrl} target="_blank">
                  Terms Of Engagement
                </Link>
              </p>
              {errors.isTermsOfEngagementAccepted?.message && (
                <Span_Error>
                  {errors.isTermsOfEngagementAccepted?.message}
                </Span_Error>
              )}
            </Col>
          </Row>
          <Span_MB20 />
          <SectionHeader>
            Medical Statement
            <RequiredAsterisk />
          </SectionHeader>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <p>
                <Input
                  name="isMedicalStatementAccepted"
                  type="checkbox"
                  {...register("isMedicalStatementAccepted")}
                />{" "}
                I have read and accept the{" "}
                <Link href={medicalStatementUrl} target="_blank">
                  Medical Statement
                </Link>
              </p>
              {errors.isMedicalStatementAccepted?.message && (
                <Span_Error>
                  {errors.isMedicalStatementAccepted?.message}
                </Span_Error>
              )}
            </Col>
          </Row>
          <Span_MB20 />
          <SectionHeader>
            Uniform Statement
            <RequiredAsterisk />
          </SectionHeader>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <p>
                <Input
                  name="isUniformStatementAccepted"
                  type="checkbox"
                  {...register("isUniformStatementAccepted")}
                />{" "}
                I have read and accept the{" "}
                <Link href={uniformStatementUrl} target="_blank">
                  Uniform Statement
                </Link>
              </p>
              {errors.isUniformStatementAccepted?.message && (
                <Span_Error>
                  {errors.isUniformStatementAccepted?.message}
                </Span_Error>
              )}
            </Col>
          </Row>
          <Span_MB20 />
          <SectionHeader>
            GDPR Consent Form
            <RequiredAsterisk />
          </SectionHeader>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <p>
                <Input
                  name="isGDPRConsentFormAgreed"
                  type="checkbox"
                  {...register("isGDPRConsentFormAgreed")}
                />{" "}
                I have read and agreed to the{" "}
                <Link href={GDPRConsentFormUrl} target="_blank">
                  GDPR Consent Form
                </Link>
              </p>
              {errors.isGDPRConsentFormAgreed?.message && (
                <Span_Error>
                  {errors.isGDPRConsentFormAgreed?.message}
                </Span_Error>
              )}
            </Col>
          </Row>
          <Span_MB20 />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <p>
                By registering you agree to the working agreements shown above.
              </p>
            </Col>
          </Row>
          <Span_MB20 />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <SectionHeader>
                Opt-Out of 48 Hour Working Agreement
              </SectionHeader>
              <p>
                {" "}
                I have agreed to one of the following 48 Hour Working Agreement
                options:
              </p>
              <PBold>1. DEFINITIONS</PBold>
              <p>
                1.1 In this Agreement the following definitions apply:
                "Assignment" means the period in which the worker is engaged to
                render services to the client; "Client" means the person, firm
                or corporate body engaging the services of the worker; "Working
                Week" means an average of 48 hours each week calculated over a
                17 week reference period.
              </p>
              <p>
                1.2 References ot the singular include the plural and references
                to the masculine include the feminine and vice versa.
              </p>
              <p>
                1.3 The headings contained in this Agreement are for convenience
                only and do not affect their interpretation.
              </p>
              <PBold>2. RESTRICTION</PBold>
              <p>
                2.1 The Working Time Regulations 1998 states that the Temporary
                Worker shall not work in excess of the Working Week unless he
                agrees in writing at this limit should not apply (see Option 1
                below).
              </p>
              <PBold>3. CONSENT</PBold>
              <p>
                3.1 The Temporary Worker herby agrees that the Working Week
                limit shall not apply.
              </p>
              <PBold>4. WITHDRAWAL OF CONSENT</PBold>
              <p>
                4.1 The Temporary Worker may end this Agreement by giving 7 days
                notice in writing.
              </p>
              <p>
                4.2 For the avoidance of doubt, any notices bringing the
                Agreement to an end shall not be construed as notice of
                termination by the Temporary Worker of an Assignment with a
                Client.
              </p>
              <p>
                4.3 Upon the expiry of the notice period set out in clause 4.1
                the Working Week limit shall apply with immediate effect.
              </p>
              <PBold>5. THE LAW</PBold>
              <p>
                5.1 These Terms are governed by English Law and are subject to
                the exclusive jurisdiction of the English Courts.
              </p>
              <PBold>
                Please select either Option 1 <u>OR</u> Option 2:
              </PBold>
              <PBold>Option 1</PBold>
              <p>
                <input
                  {...register("agreeWorkingTimesOptions")}
                  type="radio"
                  value="Option 1"
                  name="agreeWorkingTimesOptions"
                />
                I agree that I may work for more than the average of 48 hours
                per week. This is valid for 2 years from the date of signing. If
                I change my mind I will give my employer 3 months notice in
                writing to end this agreement
              </p>
              <PBold>Option 2</PBold>
              <p>
                <input
                  {...register("agreeWorkingTimesOptions")}
                  type="radio"
                  value="Option 2"
                  name="agreeWorkingTimesOptions"
                />
                I have decided NOT to opt out of the Working Time Directive, I
                will keep detailed and accurate information on the number of
                hours that I have worked and will provide the details to my
                employer.
              </p>
              <Span_MB20 />
            </Col>
          </Row>
          <Span_MB20 />
          <SectionHeader>Employee Statement</SectionHeader>
          <TitleText fontWeight="bold" fontSize="12">
            <Input
              {...register("employeeStatementOptions")}
              type="radio"
              value="Option A"
              name="employeeStatementOptions"
            />{" "}
            A - Do not apply this statement if you are in receipt of a State,
            Works or Private Pension.
          </TitleText>
          <span>
            Apply this statement if the following applies. This is your first
            job since 6 April and since the 6 April if you have not received any
            payments from any of the following.
            <ul>
              <li>Universal Credit/JobSeekers Allowance</li>
              <li>Employment and Support Allowance</li>
              <li>PIP/Incapacity Benefit/DLA</li>
            </ul>
          </span>
          <TitleText fontWeight="bold" fontSize="12px">
            <Input
              {...register("employeeStatementOptions")}
              type="radio"
              value="Option B"
              name="employeeStatementOptions"
            />{" "}
            B - Do not apply this statement if you are in receipt of a State,
            Works or Private Pension.
          </TitleText>
          <span>
            Choose this statement if the following applies. Since the 6 April if
            you have had another job, but you do not have a P45. And/or since
            the 6 April you hace received payments from any of the following:
            <ul>
              <li>Universal Credit/JobSeekers Allowance</li>
              <li>Employment and Support Allowance</li>
              <li>PIP/Incapacity Benefit/DLA</li>
            </ul>
          </span>
          <TitleText fontWeight="bold" fontSize="12px">
            <Input
              {...register("employeeStatementOptions")}
              type="radio"
              value="Option C"
              name="employeeStatementOptions"
            />{" "}
            C - Choose this statement if:
          </TitleText>
          <ul>
            <li>You have had another job and/or</li>
            <li>You are in receipt of a State, Works or Private Pension.</li>
          </ul>
          <Span_MB20 />
          <TitleText fontWeight="bold" fontSize="12px">
            Student Loan
          </TitleText>
          <p>
            Tick if yes{" "}
            <Input
              name="isStudentLoan"
              type="checkbox"
              {...register("isStudentLoan")}
            />{" "}
            Is a student loan to continue to be deducted from pay or were
            studies completed/left before the 6th April? (this option should not
            be selected if no student loan is due or it is being paid directly
            via direct debit)
          </p>
          <Span_MB20 />
          <TitleText fontWeight="bold" fontSize="12">
            Postgraduate Loan
          </TitleText>
          <p>
            Tick if yes{" "}
            <Input
              name="isPostgraduateLoan"
              type="checkbox"
              {...register("isPostgraduateLoan")}
            />{" "}
            Is a postgraduate loan to continue to be deducted from pay or were
            postgraduate completed/left before the 6th April? (this option
            should not be selected if no postgraduate loan is due or it is being
            paid directly via direct debit)
          </p>
          <p>Postgraduate loan applies if:</p>
          <ul>
            <li>
              You lived in England and started a Postgraduate Master's course on
              or after 1 August 2016
            </li>
            <li>
              You lived in Wales and started a Postgraduate Master's course on
              or after 1 August 2017
            </li>
            <li>
              You lived in England or Wales and started a Postgraduate Doctoral
              course on or after 1 August 2018
            </li>
            <li>
              If a postgraduate course was completed or left before 6th April
            </li>
          </ul>
          <Span_MB20 />
          <SectionHeader>Please Sign</SectionHeader>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Full Name
                  <RequiredAsterisk />
                </Label>
                <Input
                  name="signatureFullName"
                  {...register("signatureFullName")}
                />
                {errors.signatureFullName && (
                  <Span_Error>This field is required</Span_Error>
                )}
              </Div_Field>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Div_Field>
                <Label>
                  Date (DD/MM/YYYY)
                  <RequiredAsterisk />
                </Label>
                <Controller
                  name="signatureDate"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
                {errors.signatureDate && (
                  <Span_Error>This field is required</Span_Error>
                )}
              </Div_Field>
            </Col>
          </Row>
          <Span_MB20 />
          <div>
            <div
              style={{
                width: "100%",
                height: "500px",
                border: "1px grey solid"
              }}
            >
              <Signature_Container>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    style: { width: "100%", height: "500px" }
                  }}
                />
              </Signature_Container>
            </div>
            {signatureError && <Span_Error>Signature is required</Span_Error>}

            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "space-evenly"
              }}
            >
              <SubmitButton isReadyToSubmit={true} onClick={e => save(e)}>
                Use Signature
              </SubmitButton>
              <CancelButton onClick={e => clear(e)}>
                Clear Signature
              </CancelButton>
            </div>
          </div>
          {imageURL && (
            <div style={{ float: "left" }}>
              <p>Preview your signature before continuing</p>
              <img
                src={imageURL}
                alt="my signature"
                style={{
                  margin: "0 auto",
                  border: "1px solid black",
                  width: "300px"
                }}
              />
            </div>
          )}
          <Span_MB20 />
          <ButtonContainerDiv>
            <SubmitButton
              type="submit"
              isReadyToSubmit={!isEmailTaken}
              disabled={isEmailTaken}
            >
              Continue
            </SubmitButton>
            <CancelButton type="button" onClick={onCancel}>
              Cancel
            </CancelButton>
          </ButtonContainerDiv>{" "}
        </Form>
      </Container>
    </ContainerDiv>
  );
};

export default createEvolveMember(EvolveForm);
