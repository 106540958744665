import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

export const CHECK_MEMBER_EMAIL = gql`
  query checkMemberEmail($email: String) {
    checkMemberEmail(email: $email) {
      isEmailTaken
    }
  }
`;

export const GET_INVITED_MEMBER_DETAILS = gql`
  query getInvitedMemberDetails($hashedId: String) {
    invitedMemberDetails(hashedId: $hashedId) {
      id
      email
      firstName
      lastName
    }
  }
`;

const CREATE_EVOLVE_MEMBER = gql`
  mutation createEvolveMember(
    $id: ID
    $title: String
    $nickname: String
    $firstName: String
    $lastName: String
    $middleName: String
    $birthDate: Date
    $gender: String
    $nationality: String
    $ethnicity: String
    $disability: String
    $email: String
    $phoneNumber: String
    $mobileNumber: String
    $phoneInternational: String
    $houseNumber: String
    $streetName: String
    $town: String
    $postcode: String
    $county: String
    $country: String
    $emergencyContactTitle: String
    $emergencyContactName: String
    $emergencyContactRelationship: String
    $emergencyContactNumber: String
    $emergencyContactEmail: String
    $bankName: String
    $bankBranch: String
    $accountName: String
    $sortCode: String
    $rollNumber: String
    $accountNumber: String
    $NINumber: String
    $serviceAreas: [ID]
    $venueId: ID
    $state: String
    $internalId: String
    $metadata: JSON
    $roles: [JSON]
    $rightToWorkExpiresAt: Date
    $isRightToWorkValid: Boolean
    $startDate: Date
    $endDate: Date
    $tags: [TagsInputType]
    $memberType: String
    $isInsideIR35: Boolean
    $annualSalary: Float
    $weeklyContractedHours: Int
    $UTRNumber: String
    $registeredNumber: String
    $name: String
    $registeredAddress: String
    $invoicingAddress: String
    $isVATRegistered: Boolean
    $VATRegistrationNumber: String
    $isCompanyRegistrationCertificateAttached: Boolean
    $isHMRCSelfEmployedConfirmationCertificateAttached: Boolean
    $isConfirmationCertificateChecked: Boolean
    $isConfirmationOfRightToWork: Boolean
    $isPublicLiabilityProofAttached: Boolean
    $isContractForServicesSigned: Boolean
    $isConfirmationOfBankDetailsAttached: Boolean
    $isVATCertificateAttached: Boolean
    $isVATCertificateChecked: Boolean
    $isKeyInformationDocumentAccepted: Boolean
    $isTermsOfEngagementAccepted: Boolean
    $isMedicalStatementAccepted: Boolean
    $isUniformStatementAccepted: Boolean
    $isGDPRConsentFormAgreed: Boolean
    $agreeWorkingTimesOptions: String
    $employeeStatementOptions: String
    $isStudentLoan: Boolean
    $isPostgraduateLoan: Boolean
    $isEvolveOnboarding: Boolean
    $signatureImage: String
    $signatureFullName: String
    $signatureDate: Date
    $isCriminalConviction: Boolean
    $isFoodHygieneCertificate: Boolean
    $methodOfTransport: String
    $referralMethod: String
  ) {
    createEvolveMember(
      id: $id
      title: $title
      nickname: $nickname
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      birthDate: $birthDate
      gender: $gender
      nationality: $nationality
      ethnicity: $ethnicity
      disability: $disability
      email: $email
      phone: $phoneNumber
      mobileNumber: $mobileNumber
      phoneInternational: $phoneInternational
      houseNumber: $houseNumber
      streetName: $streetName
      town: $town
      postcode: $postcode
      county: $county
      country: $country
      emergencyContactTitle: $emergencyContactTitle
      emergencyContactName: $emergencyContactName
      emergencyContactRelationship: $emergencyContactRelationship
      emergencyContactNumber: $emergencyContactNumber
      emergencyContactEmail: $emergencyContactEmail
      bankName: $bankName
      bankBranch: $bankBranch
      accountName: $accountName
      sortCode: $sortCode
      rollNumber: $rollNumber
      accountNumber: $accountNumber
      NINumber: $NINumber
      serviceAreas: $serviceAreas
      venueId: $venueId
      state: $state
      internalId: $internalId
      metadata: $metadata
      roles: $roles
      rightToWorkExpiresAt: $rightToWorkExpiresAt
      isRightToWorkValid: $isRightToWorkValid
      startDate: $startDate
      endDate: $endDate
      tags: $tags
      memberType: $memberType
      isInsideIR35: $isInsideIR35
      annualSalary: $annualSalary
      weeklyContractedHours: $weeklyContractedHours
      UTRNumber: $UTRNumber
      name: $name
      registeredNumber: $registeredNumber
      registeredAddress: $registeredAddress
      invoicingAddress: $invoicingAddress
      isVATRegistered: $isVATRegistered
      VATRegistrationNumber: $VATRegistrationNumber
      isCompanyRegistrationCertificateAttached: $isCompanyRegistrationCertificateAttached
      isHMRCSelfEmployedConfirmationCertificateAttached: $isHMRCSelfEmployedConfirmationCertificateAttached
      isConfirmationCertificateChecked: $isConfirmationCertificateChecked
      isConfirmationOfRightToWork: $isConfirmationOfRightToWork
      isPublicLiabilityProofAttached: $isPublicLiabilityProofAttached
      isContractForServicesSigned: $isContractForServicesSigned
      isConfirmationOfBankDetailsAttached: $isConfirmationOfBankDetailsAttached
      isVATCertificateAttached: $isVATCertificateAttached
      isVATCertificateChecked: $isVATCertificateChecked
      isKeyInformationDocumentAccepted: $isKeyInformationDocumentAccepted
      isTermsOfEngagementAccepted: $isTermsOfEngagementAccepted
      isMedicalStatementAccepted: $isMedicalStatementAccepted
      isUniformStatementAccepted: $isUniformStatementAccepted
      isGDPRConsentFormAgreed: $isGDPRConsentFormAgreed
      agreeWorkingTimesOptions: $agreeWorkingTimesOptions
      employeeStatementOptions: $employeeStatementOptions
      isStudentLoan: $isStudentLoan
      isPostgraduateLoan: $isPostgraduateLoan
      isEvolveOnboarding: $isEvolveOnboarding
      signatureImage: $signatureImage
      signatureFullName: $signatureFullName
      signatureDate: $signatureDate
      isCriminalConviction: $isCriminalConviction
      isFoodHygieneCertificate: $isFoodHygieneCertificate
      methodOfTransport: $methodOfTransport
      referralMethod: $referralMethod
    ) {
      success
    }
  }
`;

const createEvolveMember = graphql(CREATE_EVOLVE_MEMBER, {
  name: "createEvolveMember",
  props: props => ({
    createEvolveMember: async (memberId, values) => {
      const memberMutationResult = await props.createEvolveMember({
        variables: { ...values, id: memberId },
        refetchQueries: props.isAddMember ? [] : ["getMember"]
      });
      return memberMutationResult.data.createEvolveMember;
    }
  })
});

export default createEvolveMember;
