import React, { useState } from "react";
import styled from "styled-components";
import { RotaButtonNew, iconNew } from "@teamrota/rota-design";

import SlideDialog from "./dialogs/slide-dialog/slide-dialog";
import AlertDialog from "./dialogs/alert-dialog/alert-dialog";
import InvitesTable from "~/src/containers/invites/InvitesTable";
import { RotaModal } from "@teamrota/rota-design";

const { Plus } = iconNew;

const PageWrapper = styled.div`
  padding: 36px;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.white};
  width: 1294px;
  height: auto;
  padding: 37px;
  border-radius: 20px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.neutral.light};
  font-weight: 500;
  font-size: 20px;
`;

const CardTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
`;

const Subtitle = styled.p`
  font-weight: 300;
  font-size: 13px;
`;

const Invites = () => {
  const [openSlideDialog, setOpenSlideDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenSlide = () => {
    setOpenSlideDialog(true);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <PageWrapper>
      <Title>Invites</Title>
      <Card>
        <div>
          <CardTitle>Email Invitations</CardTitle>
          <Subtitle>
            Send emails with a sign up link direct to potential candidates and
            have them sign up directly to the Rota system.
          </Subtitle>
        </div>
        <RotaButtonNew iconLeft={<Plus />} onClick={handleClickOpenSlide}>
          Invite New
        </RotaButtonNew>
        {openSlideDialog && (
          <RotaModal>
            <SlideDialog
              setOpenSlideDialog={setOpenSlideDialog}
              openSlideDialog={openSlideDialog}
              handleClickOpenDialog={handleClickOpenDialog}
            />
          </RotaModal>
        )}

        <AlertDialog
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          handleClose={handleDialogClose}
          dialogTitle="Sent Successfully"
          dialogText="The email invitation has been successfully sent to the recipient."
          declineBtnText="Close"
          image="PAPER_AIRPLANE"
        >
          <p>
            The email invitation has been successfully sent to the recipient.
          </p>
        </AlertDialog>
        <InvitesTable />
      </Card>
    </PageWrapper>
  );
};

export default Invites;
