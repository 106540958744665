import React from "react";
import { Role } from "@teamrota/authlib";
import { useMutation } from "@apollo/client";
import useAuth from "~/src/auth/hooks/use-auth";
import EditRoles from "~/src/components/new-pay-rate/edit-roles";
import UPDATE_TARGET_ACCOUNT_ROLE_RATES from "../graphql/update-target-account-role-rates";

const EditRolesComponent = ({
  accountId,
  roles,
  roleRates,
  uncalculatedRoleRates,
  tags,
  isPartner,
  disableEditRole,
  editButtonStyles,
  isUncalculated
}) => {
  const auth = useAuth();
  const [updateTargetAccountRoleRates, { error }] = useMutation(
    UPDATE_TARGET_ACCOUNT_ROLE_RATES,
    {
      refetchQueries: ["getTargetAccount", "getProfile"]
    }
  );
  const hasPermissionView = auth.hasRole(Role.PARTNER_VIEW);

  return (
    <EditRoles
      accountId={accountId}
      allRoles={roles || []}
      assignedRoles={roleRates || []}
      isPartner={isPartner}
      error={error}
      fields={[
        ...(hasPermissionView
          ? [
              {
                id: "payRate",
                label: "Pay Rate",
                getFromExisting: (roleRate, role) =>
                  role ? role?.payRate : roleRate?.defaultPayRate,
                shouldBeCentered: true
              }
            ]
          : []),
        ...(hasPermissionView
          ? [
              {
                id: "chargeRate",
                label: "Charge Rate",
                getFromExisting: (roleRate, role) => {
                  if (role) return role.chargeRate;
                  return roleRate.defaultPayRate;
                },
                shouldBeCentered: true
              }
            ]
          : [])
      ]}
      onSave={roleRates => {
        updateTargetAccountRoleRates({
          variables: {
            roleRates,
            id: accountId
          }
        });
      }}
      getSelectedRole={role => ({
        chargeRate: role?.defaultChargeRate,
        payRate: role?.defaultPayRate,
        overstayPayRate: role?.defaultOverstayPayRate,
        isOverstayRate: role?.isOverstayRate,
        overstayChargeRate: role?.defaultOverstayChargeRate,
        overstayHoursLimit: role?.defaultOverstayHoursLimit,
        isOvertimeRate: role?.isOvertimeRate,
        overtimePayRate: role?.defaultOvertimePayRate,
        overtimeChargeRate: role?.defaultOvertimeChargeRate,
        overtimeHoursLimit: role?.defaultOvertimeHoursLimit,
        roleId: role?.id,
        name: role?.name,
        tags,
        isDayRate: role?.isDayRate
      })}
      editButtonStyles={editButtonStyles}
      disableEditRole={disableEditRole}
      isUncalculated={isUncalculated}
      uncalculatedRoleRates={uncalculatedRoleRates}
    />
  );
};

export default EditRolesComponent;
