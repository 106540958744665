import * as React from "react";
import { RotaButton } from "@teamrota/rota-design";
import toNumber from "lodash/fp/toNumber";
import isInteger from "lodash/fp/isInteger";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NOTIFICATION_TYPES } from "~/src/consts";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";
import PageHeader from "~/src/components/page-header";
import Modal from "~/src/components/modal";
import ComposeMessage from "./components/compose-message";
import Members from "./components/members";
import AddMembers from "./components/add-members";
import Shifts from "./components/shifts";
import Filters from "./components/filters";
import {
  onToggleNotificationType,
  onComposeMessage,
  onUpdateRecipients,
  onUpdateActiveTab,
  onResetReducer
} from "./reducer";
import getMembers from "./graphql/get-members";
import sendNotifications from "./graphql/send-notifications";
import {
  Container,
  StyledButton,
  Divider,
  Panel,
  Title,
  ModalContent,
  ButtonContainer,
  Scroll
} from "./notifications.styles";

const NOTIFICATION_TYPE_BUTTONS = [
  {
    label: "Email",
    value: NOTIFICATION_TYPES.EMAIL
  },
  {
    label: "SMS",
    value: NOTIFICATION_TYPES.TEXT
  },
  {
    label: "Push",
    value: NOTIFICATION_TYPES.PUSH_NOTIFICATION
  }
];

const LIMIT = 18;

const bindActions = dispatch =>
  bindActionCreators(
    {
      onToggleNotificationType,
      onComposeMessage,
      onUpdateRecipients,
      onUpdateActiveTab,
      onResetReducer
    },
    dispatch
  );
@connect(
  s => ({
    activeNotificationTypes: s.notifications.activeNotificationTypes,
    subject: s.notifications.subject,
    messageBody: s.notifications.messageBody,
    shiftId: s.notifications.shiftId,
    recipientMembers: s.notifications.recipientMembers,
    activeTab: s.notifications.activeTab,
    memberSearchText: s.notifications.memberSearchText,
    selectedFilters: s.notifications.selectedFilters,
    selectedFilterOptions: s.notifications.selectedFilterOptions
  }),
  bindActions
)
@sendNotifications
@getMembers(LIMIT)
export default class Notifications extends React.Component {
  state = {
    isToAllMembers: false,
    composeOpen: false
  };

  onSubmitMessage = async () => {
    const { props, state } = this;

    const body = props.messageBody.toString("markdown");

    const errors = [];

    if (!body.trim().length) {
      errors.push("You must supply a message");
    }

    let shiftId = null;
    if (
      props.activeNotificationTypes.includes(
        NOTIFICATION_TYPES.PUSH_NOTIFICATION
      ) &&
      props.memberSearchText.includes(",")
    ) {
      shiftId = toNumber(props.shiftId);
      if (props.shiftId && !isInteger(shiftId)) {
        errors.push("The supplied shift ID is not a number");
      } else if (!props.shiftId) {
        errors.push(
          "Must provide shift ID when providing a list of member IDs"
        );
      }
    }

    if (!state.isToAllMembers && !props.recipientMembers.length) {
      errors.push("You must choose some members");
    }

    if (errors.length) {
      errorModal("Notifications", errors);
      return;
    }

    if (
      await asyncConfirm(this.getConfirmMessage(), {
        confirmButtonText: "Send",
        isConfirmButtonGreen: true,
        shouldHideSubText: true
      })
    ) {
      try {
        await props.sendNotifications({
          memberIds: props.recipientMembers.map(member => member.id),
          isToAllMembers: this.state.isToAllMembers,
          body, // Raw body (no formatting) for text and push notifs
          subject: props.subject,
          bodyHTML: props.messageBody.toString("html"), // For emails
          notificationTypes: props.activeNotificationTypes,
          shiftId,
          memberSearchText: props.memberSearchText,
          selectedFilters: props.selectedFilters,
          selectedFilterOptions: props.selectedFilterOptions
        });

        props.onResetReducer();
        this.setState({ composeOpen: false });
      } catch (e) {
        errorModal(e);
      }
    }
  };

  onToggleAllMembers = () => {
    this.setState({ isToAllMembers: !this.state.isToAllMembers });
  };

  getConfirmMessage() {
    const memberCount = this.getMemberCount();

    const plural = memberCount > 1 ? "s" : "";

    return `${memberCount} recipient${plural} to receive: ${this.getNotificationLabels()}`;
  }

  getNotificationLabels() {
    return this.props.activeNotificationTypes
      .map(
        notif =>
          (NOTIFICATION_TYPE_BUTTONS.find(({ value }) => value === notif) || {})
            .label
      )
      .join(", ");
  }

  getMemberCount() {
    const { props, state } = this;
    const memberCount = state.isToAllMembers
      ? props.totalResults
      : props.recipientMembers.length;

    return memberCount;
  }

  render() {
    const { props, state } = this;

    const filterSmsOption = ({ label }) =>
      label !== "SMS" || props.isSmsOptionAvailable;

    return (
      <div>
        <PageHeader
          alignItems="center"
          title="New message"
          subtext="Sends communications"
        />
        <Container flexDirection="column" margin={`0 ${STYLE_CONSTS.UNIT_4}`}>
          <div>
            <Container>
              <Container
                width="360px"
                flex="none"
                flexDirection="column"
                margin={`0 ${STYLE_CONSTS.UNIT_3} 0 0`}
              >
                <Filters
                  onUpdateActiveTab={props.onUpdateActiveTab}
                  activeTab={props.activeTab}
                />
              </Container>
              <Container
                flex="1"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Title>Add Recipients</Title>
                <Panel flex="1" margin={`0 0 ${STYLE_CONSTS.UNIT_4} 0`}>
                  {props.activeTab === "members" && (
                    <AddMembers
                      areMembersLoading={props.areMembersLoading}
                      members={props.members}
                      totalResults={props.totalResults}
                      fetchMore={props.fetchMore}
                      limit={LIMIT}
                      onToggleAllMembers={this.onToggleAllMembers}
                      isToAllMembers={state.isToAllMembers}
                    />
                  )}
                  {props.activeTab === "shifts" && <Shifts />}
                </Panel>
                <Panel>
                  <h3>
                    Recipients (
                    {state.isToAllMembers
                      ? props.totalResults
                      : props.recipientMembers.length}
                    )
                  </h3>
                  <Divider />
                  <Scroll>
                    <Members
                      members={
                        state.isToAllMembers
                          ? props.members
                          : props.recipientMembers
                      }
                      onUpdateRecipients={props.onUpdateRecipients}
                    />
                  </Scroll>
                </Panel>
                {(props.recipientMembers.length > 0 ||
                  state.isToAllMembers) && (
                  <ButtonContainer>
                    <RotaButton
                      size="large"
                      onClick={() => this.setState({ composeOpen: true })}
                    >
                      Message members
                    </RotaButton>
                  </ButtonContainer>
                )}
              </Container>
            </Container>
          </div>

          <Modal
            isOpen={state.composeOpen}
            onClose={() => this.setState({ composeOpen: false })}
          >
            <ModalContent>
              Type&nbsp;&nbsp;
              {NOTIFICATION_TYPE_BUTTONS.filter(filterSmsOption).map(type => (
                <StyledButton
                  key={type.value}
                  isOutline={
                    !props.activeNotificationTypes.includes(type.value)
                  }
                  onClick={() =>
                    this.props.onToggleNotificationType(type.value)
                  }
                >
                  {type.label}
                </StyledButton>
              ))}
            </ModalContent>

            <ComposeMessage
              onComposeMessage={props.onComposeMessage}
              subject={props.subject}
              messageBody={props.messageBody}
              shiftId={props.shiftId}
              onSubmit={this.onSubmitMessage}
              activeNotificationTypes={props.activeNotificationTypes}
              canSendShiftID={props.memberSearchText.includes(",")}
            />
          </Modal>
        </Container>
      </div>
    );
  }
}
