import React, { useState } from "react";
import LoadMore from "~/src/components/load-more";
import BookingsList from "../bookings";
import {
  Card,
  CenterText,
  DataRow,
  LeftText,
  PeriodHeader,
  RightText,
  Section,
  SpacedIcon
} from "./pay-periods.styles";

const PayPeriods = ({ periods, isLoading, fetchMore, member }) => {
  const [currentPay, ...pastPays] = periods;

  return (
    <div>
      <Section>Current Pay Period</Section>
      <PayPeriodCard period={currentPay} member={member} />
      <Section>Past Pay Periods</Section>

      {pastPays?.map(period => (
        <PayPeriodCard period={period} key={period?.id} member={member} />
      ))}
      <LoadMore
        isLoading={isLoading}
        onLoadMore={() => fetchMore(periods?.length)}
      />
    </div>
  );
};

export const PayPeriodCard = ({ member, period }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card>
      <PeriodHeader>
        {period?.dateRange}
        <button onClick={() => toggle()}>Toggle bookings</button>
      </PeriodHeader>
      {isExpanded ? (
        <BookingsList member={member} payPeriodId={period?.id} />
      ) : (
        <div>
          <DataRow>
            <LeftText>
              <SpacedIcon name="MY_SHIFTS" size="SMALL" />
              {period?.noOfShifts}
            </LeftText>
            <CenterText>
              <SpacedIcon name="CLOCK" size="SMALL" />
              {period?.totalHours}
            </CenterText>
            <RightText>
              <SpacedIcon name="AMOUNT" size="SMALL" />
              {period?.totalPay}
            </RightText>
          </DataRow>
        </div>
      )}
    </Card>
  );
};

export default PayPeriods;
