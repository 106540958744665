import React, { useState, useEffect, useCallback, useRef } from "react";
import { has } from "lodash/fp";
import { RotaButtonIcon } from "@teamrota/rota-design";

import Notifier from "~/src/components/notifier";
import {
  ContentWrapper,
  Tab,
  TabNav,
  Wrapper,
  TabNavWrapper,
  RotaButtonIconWrapper
} from "./index.styles";

const Tabs = ({ defaultTab, tabs = [], className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [tabNavWidth, setTabNavWidth] = useState();
  const [scroll, setScroll] = useState({
    isShowLeft: false,
    isShowRight: true
  });
  const ref = useRef();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const element = entries[0];
      if (element) {
        setTabNavWidth(element.contentRect.width);
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const { scrollLeft, scrollWidth, clientWidth } = ref.current;
      const isShowRight = scrollLeft < scrollWidth - clientWidth;
      const isShowLeft = scrollLeft > 0;
      setScroll({ isShowLeft, isShowRight });
    }
  }, [tabNavWidth]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", onScroll);
    }
    return () => {
      ref.current.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  const switchTab = newTab => {
    if (activeTab !== newTab) setActiveTab(newTab);
  };

  const handleTabClickAction = tab => {
    if (has("isClickable", tab)) {
      if (tab.isClickable) switchTab(tab.name);
    } else switchTab(tab.name);
  };

  const selectedTab = tabs.find(tab => tab.name === activeTab);
  const content = selectedTab?.content || null;

  const handleScrollRight = () => {
    ref.current.scrollLeft += 150;
  };

  const handleScrollLeft = () => {
    ref.current.scrollLeft -= 150;
  };

  const onScroll = useCallback(() => {
    if (ref.current) {
      const { scrollLeft, scrollWidth, clientWidth } = ref.current;
      const isShowRight = scrollLeft < scrollWidth - clientWidth;
      const isShowLeft = scrollLeft > 0;
      setScroll({ isShowLeft, isShowRight });
    }
  }, [scroll.isShowLeft, scroll.isShowRight, setScroll]);

  return (
    <Wrapper className={className}>
      <TabNavWrapper>
        {scroll.isShowLeft && (
          <RotaButtonIconWrapper style={{ left: 0 }}>
            <RotaButtonIcon icon="arrowLeft" onClick={handleScrollLeft} />
          </RotaButtonIconWrapper>
        )}
        <TabNav center ref={ref}>
          {tabs?.map((tab, index) => (
            <Tab
              key={index}
              isUnclickable={has("isClickable", tab) ? !tab.isClickable : false}
              isActive={activeTab === tab.name}
              onClick={() => handleTabClickAction(tab)}
            >
              {tab.name}{" "}
              {tab.notifCount ? <Notifier number={tab.notifCount} /> : ""}
            </Tab>
          ))}
        </TabNav>
        {scroll.isShowRight && (
          <RotaButtonIconWrapper style={{ right: 0 }}>
            <RotaButtonIcon icon="arrowRight" onClick={handleScrollRight} />
          </RotaButtonIconWrapper>
        )}
      </TabNavWrapper>
      <ContentWrapper>{content}</ContentWrapper>
    </Wrapper>
  );
};

Tabs.ContentWrapper = ContentWrapper;
Tabs.Tab = Tab;
Tabs.TabNav = TabNav;

export default Tabs;
