import styled from "styled-components";
import Icon from "~/src/components/icon";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Card = styled.div`
  border-radius: ${STYLE_CONSTS.UNIT_1};
  box-shadow: ${STYLE_CONSTS.FLOATING_MID};
  margin: ${STYLE_CONSTS.UNIT_2} auto;
  padding: ${STYLE_CONSTS.UNIT_1};
  font-size: ${STYLE_CONSTS.FONT};
`;

export const PeriodHeader = styled.div`
  background-color: ${COLORS.CLOUDY_BLUE};
  border-radius: ${STYLE_CONSTS.UNIT_1} ${STYLE_CONSTS.UNIT_1} 0 0;
  display: flex;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  margin: -${STYLE_CONSTS.UNIT_1};
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
  padding: ${STYLE_CONSTS.UNIT_1};
  overflow: hidden;
`;

export const Section = styled.p`
  color: ${({ theme }) => theme.text.primary};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  margin: ${STYLE_CONSTS.UNIT_1} auto ${STYLE_CONSTS.UNIT_2};
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftText = styled.span`
  display: inline-flex;
  margin: ${STYLE_CONSTS.UNIT_1};
`;

export const CenterText = styled.span`
  display: inline-flex;
  margin: ${STYLE_CONSTS.UNIT_1};
`;

export const RightText = styled.span`
  display: inline-flex;
  margin: ${STYLE_CONSTS.UNIT_1};
  color: ${COLORS.DARK_GREEN};
`;

export const SpacedIcon = styled(Icon)`
  margin-right: ${STYLE_CONSTS.UNIT_1};
  vertical-align: middle;
`;
