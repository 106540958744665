import { useCallback } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { currencySupport } from "@teamrota/rota-common";
import { getFormatterMoney } from "~/src/utils/formatting";
import { TimesheetInternalStatus, TimesheetStatus } from "./types";
import { TimesheetFragment } from "./fragments";
import useAuth from "~/src/auth/hooks/use-auth";
import { Role } from "@teamrota/authlib";
import { useToast } from "@teamrota/rota-design";

const TIMESHEET_STATS = gql`
  query TimesheetsSummary(
    $startDate: Date
    $endDate: Date
    $venueIds: [ID!]!
    $roleIds: [ID!]!
    $search: String
    $statuses: [TimesheetStatusType!]
    $offset: Int!
    $limit: Int!
  ) {
    account {
      id
      logo
      currencyRegion
      timesheetsWithStats(
        startDate: $startDate
        endDate: $endDate
        venueIds: $venueIds
        roleIds: $roleIds
        search: $search
        statuses: $statuses
        offset: $offset
        limit: $limit
      ) {
        stats {
          totalShifts
          totalHours
          totalPay
          totalCharge
          totalBookings
        }
        statusCount {
          todoCount
          inProgressCount
          approvedCount
          lockedCount
        }
        timesheets {
          ...TimesheetFragment
          memberNames
        }
        previewTimesheets {
          id
          shiftName
          shiftId
          peopleCount
          time
          cost
          purchaseOrderNumber
          status
          shiftStartTime
          shiftEndTime
          venueName
          memberNames
        }
      }
    }
  }
  ${TimesheetFragment}
`;

type TimesheetBase = {
  id: string;
  shiftName: string;
  venueName: string;
  venueAddress: string;
  shiftId: string;
  peopleCount: number;
  time: number;
  cost: number;
  purchaseOrderNumber: string;
  status: TimesheetInternalStatus;
  shiftStartTime: string;
  shiftEndTime: string;
  isUncalculatedRole: boolean;
  providerName: string;
  numberRequested: number;
  hasSleepTime: boolean;
  venueId: string;
  subvenueId: string;
  subvenueName: string;
  memberNames: string[];
};

export interface Timesheet extends TimesheetBase {
  isPreview: boolean;
}

export enum StatsEnum {
  totalShifts = "Total Shifts",
  totalBookings = "Total Bookings",
  totalHours = "Total Hours",
  totalPay = "Total Pay",
  totalCharge = "Total Charge"
}

const getSelectedStatuses = (statuses: TimesheetStatus[]) => {
  return statuses.reduce<TimesheetInternalStatus[]>((acc, status) => {
    if (status === TimesheetStatus.Approved) {
      return [...acc, TimesheetInternalStatus.APPROVED];
    }
    if (status === TimesheetStatus.Locked) {
      return [...acc, TimesheetInternalStatus.LOCKED];
    }
    if (status === TimesheetStatus.Open) {
      return [
        ...acc,
        TimesheetInternalStatus.TO_DO,
        TimesheetInternalStatus.IN_PROGRESS
      ];
    }
    return acc;
  }, []);
};

interface Params {
  startDate?: Date;
  endDate?: Date;
  venueIds: string[];
  roleIds?: string[];
  search: string;
  statuses?: TimesheetStatus[];
}

export const useTimesheetsWithStats = ({
  startDate,
  endDate,
  venueIds,
  roleIds,
  search,
  statuses
}: Params) => {
  const auth = useAuth();
  const { showToast } = useToast();
  const hasProviderSchedulerRole = auth.hasRole(Role.PROVIDER_SCHEDULER);
  const result = useQuery(TIMESHEET_STATS, {
    variables: {
      startDate,
      endDate,
      venueIds,
      roleIds,
      search,
      statuses: statuses ? getSelectedStatuses(statuses) : [],
      offset: 0,
      limit: 50
    },
    fetchPolicy: "cache-and-network",
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    skip: !startDate || !endDate
  });

  const data = result.data || result.previousData;

  const stats = data?.account?.timesheetsWithStats?.stats;
  const timesheetStatusCount = data?.account?.timesheetsWithStats?.statusCount;
  const timesheets:
    | Timesheet[]
    | undefined = data?.account?.timesheetsWithStats?.timesheets?.map(
    (timesheet: TimesheetBase) => ({ ...timesheet, isPreview: false })
  );
  const previewTimesheets: Timesheet[] =
    data?.account?.timesheetsWithStats?.previewTimesheets?.map(
      (timesheet: TimesheetBase) => ({ ...timesheet, isPreview: true })
    ) || [];
  const accountId = data?.account.id;
  const currencyCode =
    data?.account?.currencyRegion &&
    currencySupport[data?.account?.currencyRegion].code;

  const formatterMoney = currencyCode && getFormatterMoney(currencyCode);

  const timesheetStats = (Object.keys(StatsEnum) as Array<
    keyof typeof StatsEnum
  >)
    .filter(key => (hasProviderSchedulerRole ? true : key !== "totalPay"))
    .map(key => ({
      label: StatsEnum[key],
      value: [StatsEnum.totalPay, StatsEnum.totalCharge].includes(
        StatsEnum[key]
      )
        ? formatterMoney?.format(stats?.[key] / 100)
        : stats?.[key] ?? "0"
    }));

  const timesheetsCount = (timesheets || []).length + previewTimesheets?.length;
  const hasMore = stats?.totalShifts > timesheetsCount;

  const loadMore = useCallback(async () => {
    if (!hasMore) return;
    await result.fetchMore({
      variables: { offset: timesheetsCount },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevData;

        return {
          ...fetchMoreResult,
          account: {
            ...fetchMoreResult.account,
            timesheetsWithStats: {
              ...fetchMoreResult.account.timesheetsWithStats,
              timesheets: [
                ...prevData.account.timesheetsWithStats.timesheets,
                ...fetchMoreResult.account.timesheetsWithStats.timesheets
              ]
            }
          }
        };
      }
    });
  }, [hasMore, timesheetsCount]);

  return {
    timesheetStats,
    totalShifts: stats?.totalShifts,
    hasMore,
    loadMore,
    timesheetStatusCount,
    logo: data?.account?.logo,
    currencyCode,
    timesheets,
    previewTimesheets,
    accountId,
    ...result
  };
};
