import React from "react";
import Icon from "~/src/components/icon";
import Loading from "~/src/components/loading";
import getPayPeriodBookings from "../../graphql/get-pay-period-bookings";
import { Card, Section, SpacedIcon } from "../pay-periods/pay-periods.styles";
import {
  BoxAmount,
  BoxRow,
  BoxTitle,
  LittleBoxes,
  Text
} from "./bookings.styles";

export const PayPeriodBookings = props => {
  const {
    isLoadingPayPeriod,
    member,
    payPeriodBookings,
    payPeriodMeta: { totalBasePay, totalBonusPay, totalPay }
  } = props;

  return isLoadingPayPeriod ? (
    <Loading color={Icon.colors.BLACK} />
  ) : (
    <div>
      <BoxRow>
        <LittleBoxes>
          <BoxTitle>BASE</BoxTitle>
          <BoxAmount>{totalBasePay}</BoxAmount>
        </LittleBoxes>
        <LittleBoxes>
          <BoxTitle>BONUS</BoxTitle>
          <BoxAmount>{totalBonusPay}</BoxAmount>
        </LittleBoxes>
        <LittleBoxes>
          <BoxTitle>TOTAL</BoxTitle>
          <BoxAmount>{totalPay}</BoxAmount>
        </LittleBoxes>
      </BoxRow>
      {!payPeriodBookings.length ? (
        <Text>No bookings in this period</Text>
      ) : (
        <div>
          <Section>Completed Bookings</Section>
          {payPeriodBookings.map(booking => {
            return (
              <BookingCard
                booking={booking}
                key={booking.bookingId}
                member={member}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export const BookingCard = props => {
  const {
    booking: { breakHours, date, hours, role, venue, totalShiftPay, hasBonus }
  } = props;

  return (
    <Card>
      <Text>{date}</Text>
      <Text>
        {hours} <SpacedIcon name="CLOCK" size="SMALL" />
        {breakHours > 0 ? ` ${breakHours}min break` : " no break"}
      </Text>
      <Text>{`${role} @ ${venue}`}</Text>
      <Text>{`${totalShiftPay} ${hasBonus ? "+ bonus" : ""}`}</Text>
    </Card>
  );
};

export default getPayPeriodBookings(PayPeriodBookings);
