import React from "react";
import sendMemberInviteEmail from "../../graphql";
import InviteForm from "../../invite-form";

import styles from "./slide-dialog.styles";

const SlideDialog = ({
  setOpenSlideDialog,
  handleClickOpenDialog,
  handleClose,
  isLoading
}) => {
  return (
    <>
      <div style={styles.titleContainer}>
        <p style={styles.dialogTitleText}>Invite New Staff Member</p>
        <p style={styles.dialogSubTitleText}>
          Send emails with a sign up link direct to potential candidates and
          have them sign up directly to the Rota system.
        </p>
      </div>
      <div style={styles.dialogContent}>
        <div id="form-container" style={styles.formContainer}>
          <p style={styles.formTitle}>Account Details</p>
          <InviteForm
            setOpenSlideDialog={setOpenSlideDialog}
            handleClickOpenDialog={handleClickOpenDialog}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default sendMemberInviteEmail(SlideDialog);
