import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Role as authRole } from "@teamrota/authlib";
import { RotaCheckbox } from "@teamrota/rota-design";
import { useLazyQuery } from "@apollo/client";
import {
  RotaButton,
  RotaButtonIcon,
  icon,
  RotaSnackBar
} from "@teamrota/rota-design";
import { GET_ROLE_RATE } from "~/src/components/RateBreakdown/graphql";
import Role from "~/src/components/new-pay-rate/role";
import useAuth from "~/src/auth/hooks/use-auth";
import EditRole from "../new-pay-rate/edit-role";
import UncalculatedRoleRateDetails from "./uncalculated-role-rate-details";
import {
  StyledRole,
  StyledSpanWrap,
  StyledWrapButtonFixed,
  StyledArrowDown
} from "./style";

const SaveWarning = styled.span`
  color: ${({ theme }) => theme.error.main};
  font-weight: normal;
  font-size: 12px;
`;

const EditRoles = ({
  allRoles,
  assignedRoles,
  emptyTagsMessage,
  getSelectedRole,
  onSave,
  accountId,
  isPartner,
  error,
  disableEditRole,
  editButtonStyles,
  isUncalculated,
  uncalculatedRoleRates
}) => {
  const [enabledRoleRates, setEnabledRoleRates] = useState([]);
  const [roleSelected, setRoleSelected] = useState(null);
  const [roleEditSelected, setRoleEditSelected] = useState(null);
  const [isEditRoles, setIsEditRoles] = useState(false);
  const [isOpenEditRoleModal, setIsOpenEditRoleModal] = useState(false);
  const [snackBar, setSnackBar] = useState({});

  const [getRoleRate, { data }] = useLazyQuery(GET_ROLE_RATE, {
    fetchPolicy: "network-only"
  });

  const auth = useAuth();
  const hasPartnerAuthorisation = auth.hasRole(authRole.PARTNER_EDIT);

  const { ArrowTop } = icon;

  useEffect(() => {
    setEnabledRoleRates(assignedRoles);
  }, [assignedRoles]);

  const allAccountRoles = [...allRoles].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );

  const findByRoleId = (roles, id, startDate) =>
    roles.find(
      r =>
        parseInt(r.roleId) === parseInt(id) &&
        Date(r.startDate) === Date(startDate)
    );

  const filterByRoleId = (roles, id, startDate) =>
    roles.filter(
      r =>
        !(
          parseInt(r.roleId) === parseInt(id) &&
          Date(r.startDate) === Date(startDate)
        )
    );

  const onSelect = (id, startDate) => {
    const existingRole = findByRoleId(enabledRoleRates, id, startDate);
    if (existingRole) {
      setEnabledRoleRates(filterByRoleId(enabledRoleRates, id, startDate));
    } else {
      const role = allRoles?.find(
        r => r.id === id && r.startDate === startDate
      );
      setEnabledRoleRates([...(enabledRoleRates || []), getSelectedRole(role)]);
    }
  };

  const onSaveSelectedRole = async () => {
    await onSave(enabledRoleRates);
    if (error) {
      setSnackBar({
        open: true,
        message: "Something went wrong. Please try again",
        severity: "error"
      });
    } else {
      setIsEditRoles(false);
      setSnackBar({
        open: true,
        message: "Saved successfully.",
        severity: "success"
      });
    }
  };

  let isEnableRoleTextShown = false;
  if (
    allAccountRoles?.length &&
    !enabledRoleRates.find(enabledRole =>
      allAccountRoles.find(accountRole => accountRole.id === enabledRole.roleId)
    ) &&
    !isEditRoles
  )
    isEnableRoleTextShown = true;
  else isEnableRoleTextShown = false;

  return (
    <div>
      <RotaButton
        onClick={() => setIsEditRoles(true)}
        disabled={isEditRoles}
        style={{
          float: "right",
          marginTop: "-40px",
          ...(editButtonStyles || {})
        }}
      >
        Enable roles
      </RotaButton>
      <div style={{ padding: "30px 0 0 0" }}>
        {isEnableRoleTextShown ? (
          <p>Use Enable roles to add roles</p>
        ) : allAccountRoles.length ? (
          allAccountRoles?.map(role => {
            const selectedRoleRate = enabledRoleRates.find(
              memberRole => memberRole.roleId === role.id
            );

            const isCurrentlyEditedRole = !!(
              selectedRoleRate &&
              !selectedRoleRate?.id &&
              isEditRoles
            );

            return (
              <Fragment key={role.id}>
                {(selectedRoleRate || isEditRoles) && (
                  <StyledRole>
                    <StyledSpanWrap>
                      <>
                        {isEditRoles && (
                          <RotaCheckbox
                            isChecked={!!selectedRoleRate}
                            onClick={() => onSelect(role.id, role.startDate)}
                          />
                        )}
                        <p>
                          {role.name}
                          {isCurrentlyEditedRole && (
                            <SaveWarning> *Save to activate</SaveWarning>
                          )}
                        </p>
                        {selectedRoleRate?.id && (
                          <>
                            {roleSelected === role.id ? (
                              <span
                                onClick={() => {
                                  setRoleSelected("");
                                }}
                              >
                                <ArrowTop
                                  styles={{
                                    cursor: "pointer",
                                    fontWeight: "400",
                                    marginLeft: "5px"
                                  }}
                                />
                              </span>
                            ) : (
                              <StyledArrowDown
                                onClick={() => {
                                  setRoleSelected(role.id);
                                  getRoleRate({
                                    variables: {
                                      roleRateId: selectedRoleRate?.id
                                    },
                                    skip: !selectedRoleRate?.id
                                  });
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    </StyledSpanWrap>
                    {(hasPartnerAuthorisation || !isPartner) &&
                      selectedRoleRate?.id &&
                      !disableEditRole && (
                        <RotaButtonIcon
                          icon="edit"
                          style={{ marginTop: "10px" }}
                          onClick={() => {
                            setIsOpenEditRoleModal(true);
                            setRoleEditSelected(role.id);
                          }}
                        />
                      )}
                  </StyledRole>
                )}
                {roleSelected === role.id &&
                  selectedRoleRate &&
                  (isUncalculated ? (
                    <UncalculatedRoleRateDetails
                      uncalculatedRoleRates={uncalculatedRoleRates.filter(
                        uncalculatedRoleRate =>
                          uncalculatedRoleRate.roleId === role.id
                      )}
                    />
                  ) : (
                    <Role
                      selectedRoleRate={selectedRoleRate}
                      accountId={accountId}
                      id={role.id}
                      emptyTagsMessage={emptyTagsMessage}
                      roleRate={data?.account?.roleRateById}
                      isRoleRatesModal
                    />
                  ))}
                {isOpenEditRoleModal && roleEditSelected === role.id && (
                  <EditRole
                    selectedRoleRate={selectedRoleRate || []}
                    name={role?.name}
                    isDayRate={role?.isDayRate}
                    onClose={() => setIsOpenEditRoleModal(false)}
                    setIsOpenEditRoleModal={setIsOpenEditRoleModal}
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <p>No Roles</p>
        )}
      </div>
      {isEditRoles && (
        <StyledWrapButtonFixed>
          <RotaButton
            onClick={() => onSaveSelectedRole()}
            style={{ float: "right" }}
          >
            Save
          </RotaButton>
        </StyledWrapButtonFixed>
      )}
      <RotaSnackBar
        snackOpen={snackBar.open}
        onClose={() => {}}
        message={snackBar.message}
        severity={snackBar.severity}
      />
    </div>
  );
};

export default EditRoles;
