import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import styles, {
  Form,
  Field,
  Label,
  Input,
  Select,
  Button,
  Row,
  List,
  ListItem,
  StyledIcon,
  Span
} from "./styles";
import Icon from "~/src/components/icon";
import { RotaSnackBar } from "@teamrota/rota-design";

import getEvolveClientInformation from "./graphql/get-evolve-client-information";
import createOrUpdateEvolveClientInformation from "./graphql/update-evolve-client-information";

const locationOptions = ["London"];

const departmentOptions = ["Front of House"];

const accountingCyclesOptions = [
  "Monday - Sunday",
  "Thursday - Wednesday",
  "Saturday - Friday",
  "Weekly",
  "Monthly"
];

const schema = yup.object().shape({
  retroPercentage: yup
    .number()
    .min(0)
    .max(100)
    .typeError("must be a number between 0 and 100")
});

const ClientInfo = ({
  accountId,
  loading,
  evolveClientInformation,
  createOrUpdateEvolveClientInformation
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [isErrors, setIsErrors] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: evolveClientInformation
  });

  const watchIsParent = watch("isParent");
  const watchParentId = watch("parentId");

  useEffect(() => {
    reset(evolveClientInformation);
  }, [evolveClientInformation]);

  const onSubmit = async data => {
    setIsUpdating(true);
    try {
      await createOrUpdateEvolveClientInformation({
        accountId: parseInt(accountId),
        ...data
      });
    } catch (e) {
      setIsErrors(true);
    } finally {
      setIsUpdating(false);
      setIsSnackOpen(true);
    }
  };

  const removeParent = async accountId => {
    try {
      await createOrUpdateEvolveClientInformation({
        accountId,
        parentId: null,
        retroPercentage: null,
        accountingCycles: null
      });
    } catch (e) {
      setIsErrors(true);
    } finally {
      setIsSnackOpen(true);
    }
  };

  return (
    !loading && (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2>Client Information</h2>
          <Row>
            <Field>
              <Label>Location</Label>
              <Select {...register("location")}>
                <option value="">Select location</option>{" "}
                {locationOptions.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
              <p>{errors.location?.message}</p>
            </Field>
            <Field>
              <Label>Department</Label>
              <Select {...register("department")}>
                <option value="">Select department</option>{" "}
                {departmentOptions.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
              <p>{errors.department?.message}</p>
            </Field>
          </Row>
          <h2>Manage Parent</h2>
          {!watchParentId && (
            <Row>
              <Field>
                <Label>
                  <input
                    style={styles.Checkbox}
                    type="checkbox"
                    {...register("isParent")}
                  />
                  Client is Parent
                </Label>
                <p>{errors.isParent?.message}</p>
              </Field>
              <Field>
                <Label>Child Relationships</Label>
                {!!evolveClientInformation?.children?.length && (
                  <List>
                    {evolveClientInformation?.children.map(
                      ({ id, accountName }) => (
                        <li key={id}>
                          <ListItem>
                            <Span>{accountName}</Span>
                            <StyledIcon
                              color={Icon.colors.MAIN_ORANGE}
                              name={Icon.names.DELETE}
                              size={Icon.sizes.SMALL}
                              onClick={() => removeParent(id)}
                            />
                          </ListItem>
                        </li>
                      )
                    )}
                  </List>
                )}
              </Field>
            </Row>
          )}
          {!watchIsParent && (
            <Row>
              <Field>
                <Label>Parent</Label>
                <Select {...register("parentId")}>
                  <option value="">Select parent name</option>{" "}
                  {evolveClientInformation?.parents.map(
                    ({ id, accountName }) => (
                      <option key={id} value={id}>
                        {accountName}
                      </option>
                    )
                  )}
                </Select>
                <p>{errors.parentId?.message}</p>
              </Field>
            </Row>
          )}
          <h2>Retro</h2>
          <Row>
            <Field>
              <Label>Retro %</Label>
              <Input
                disabled={!!watchParentId}
                {...register("retroPercentage")}
              />
              <p>{errors.retroPercentage?.message}</p>
            </Field>
            <Field>
              <Label>Accounting Cycles</Label>
              <Select
                disabled={!!watchParentId}
                {...register("accountingCycles")}
              >
                <option value="">Select accounting cycle</option>{" "}
                {accountingCyclesOptions.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
              <p>{errors.accountingCycles?.message}</p>
            </Field>
          </Row>
          <Row noPadding>
            <Button disabled={isUpdating} type="submit">
              Save
            </Button>
          </Row>
        </Form>

        <RotaSnackBar
          snackOpen={isSnackOpen}
          onClose={() => setIsSnackOpen(false)}
          message={isErrors ? "Error" : "Success"}
          severity={isErrors ? "error" : "success"}
        />
      </>
    )
  );
};

export default createOrUpdateEvolveClientInformation(
  getEvolveClientInformation(ClientInfo)
);
