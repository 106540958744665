export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_DEVELOPMENT = !IS_PRODUCTION;

const host = window.location.hostname;
const sentryDSN =
  "https://ea30f94cf931457e9ba72fbc97a27012@o96321.ingest.sentry.io/210928";

let auth = "//signin.rota.com";
let api = "//api.rota.com";

let showDebug = true;
let sentryOpts = null;

if (IS_DEVELOPMENT || host.includes("localhost")) {
  auth = "//localhost:9005";
  api = "//localhost:9000";
} else {
  const envMatch = host.match(
    /(app(?:-preview)?)\.((?:[a-z0-9-]+\.)?[a-z0-9-]+)\.rota\.com/
  );

  if (envMatch) {
    const endpoint = envMatch[1] === "app-preview" ? "api-preview" : "api";
    const environment = envMatch[2];

    auth = `//signin.${environment}.rota.com`;
    api = `//${endpoint}.${environment}.rota.com`;

    sentryOpts = {
      environment,
      dsn: sentryDSN,
      defaultIntegrations: false
    };
  } else {
    showDebug = false;
    sentryOpts = {
      environment: "production",
      dsn: sentryDSN,
      defaultIntegrations: false
    };
  }
}

export const GRAPHQL_ENDPOINT = `${api}/graphql`;
export const GRAPHQL_ADMIN_ENDPOINT = `${api}/admin`;
export const UPLOAD_URL = `${api}/upload`;
export const TIMESHEETS_ENDPOINT = `${api}/timesheets`;
export const TIMESHEETS_DOWNLOAD = `${api}/download`;
export const CHECK_ROLE_PARTNER_MANAGE_ENDPOINT = `${api}/checkRolePartnerManage`;
export const QR_CODE_URL = `${api}/qr`;

export const OPENID_ISSUER = auth;
export const SPLASH_URL = `${auth}/`;

export const SHOW_DEBUG = showDebug;
export const SENTRY_OPTS = sentryOpts;
