import React from "react";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-grid-system";

import { evolveLinks } from "@teamrota/rota-common";

import {
  ContainerDiv,
  Span_MB20,
  TitleText,
  TitleDiv,
  BackToHomeButton,
  Link
} from "../styles";

const {
  keyInformationDocumentUrl,
  termsOfEngagementUrl,
  medicalStatementUrl,
  uniformStatementUrl,
  GDPRConsentFormUrl
} = evolveLinks;

const CompleteMessage = () => {
  const history = useHistory();

  const onBackToHome = () => {
    history.push("/");
  };

  return (
    <ContainerDiv>
      <img src="https://doc.rota.com/evolve/evolve.png" />
      <Container>
        <TitleDiv>
          <TitleText>Application Form</TitleText>
        </TitleDiv>
        <Span_MB20>
          <TitleText>Thank you</TitleText>
        </Span_MB20>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <p>
              You have successfully registered with Evolve on the Rota platform.
            </p>
            <p>
              Here are links to the key agreements if you would like a copy for
              your records:
            </p>
            <p>
              <Link href={keyInformationDocumentUrl} target="_blank">
                Key Information Document
              </Link>
            </p>
            <p>
              <Link href={termsOfEngagementUrl} target="_blank">
                Terms Of Engagement
              </Link>
            </p>
            <p>
              <Link href={medicalStatementUrl} target="_blank">
                Medical Statement
              </Link>
            </p>
            <p>
              <Link href={uniformStatementUrl} target="_blank">
                Uniform Statement
              </Link>
            </p>
            <p>
              <Link href={GDPRConsentFormUrl} target="_blank">
                GDPR Consent Form
              </Link>
            </p>
          </Col>
        </Row>
        <Span_MB20 />
        <Row>
          <Col sm={12} md={6} lg={6}>
            <BackToHomeButton onClick={onBackToHome}>
              Back to home
            </BackToHomeButton>
          </Col>
        </Row>
      </Container>
    </ContainerDiv>
  );
};

export default CompleteMessage;
