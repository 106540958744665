import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import Loading from "~/src/components/loading";

import styles, { useStyles, StyledIcon } from "./alert-dialog.styles";
import DialogTitle from "../dialog-title";

const AlertDialog = ({
  openDialog,
  handleClose,
  isLoading,
  dialogTitle,
  dialogText,
  onClickHandler,
  declineBtnText,
  acceptBtnText,
  image
}) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      transitionDuration={{ exit: 700 }}
      TransitionProps={{
        style: {
          transitionDelay: 500
        }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "21px",
          borderBottom: "#EBEBEB 1px solid"
        }}
      >
        {image && (
          <div
            id="icon-wrapper"
            style={{
              border: "2px solid #16C13C",
              borderRadius: "50%",
              backgroundColor: "#D5FFDB"
            }}
          >
            <StyledIcon name={image} size="XLARGE" color="white" />
          </div>
        )}
        <p style={styles.dialogTitleText}>{dialogTitle}</p>
      </div>
      <div style={styles.dialogContent}>
        <div style={styles.dialogText}>{dialogText}</div>
      </div>
      <DialogActions style={styles.dialogActions}>
        {acceptBtnText && (
          <Button autoFocus onClick={onClickHandler} style={styles.button}>
            {!isLoading && acceptBtnText}
            {""} {isLoading && <Loading color="black" />}
          </Button>
        )}

        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          style={styles.declineBtn}
        >
          {declineBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
